import React, { MouseEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { StyledHeader } from "../ActionsV3/SelectableItem";
import { Permission } from "../../../../util";
import _ from "lodash";
import { capitalizeFirstLetter, filterTableInfo } from "../../util";
import {
  Device,
  changeDeviceStatus,
  downloadCertificates,
  fetchAllDashboards,
  fetchDeviceWithSpecificAction,
  fetchTableInfo,
  fetchAllDeviceActions,
  getTenantFromURL,
  markActionAsCompleted,
  fetchAllStreamsWithDetails,
  FetchStreamsAPIResponse,
  completeAllActionsForDevices,
  // devicesPerPageOptions,
} from "../../../../BytebeamClient";
import DeviceOverview from "../../DeviceManagement/Devices/DeviceOverview";
import { LastActionType } from "../../DeviceManagement/Devices/LastActionInfoTable";
import {
  // Button,
  Grid,
  Icon,
  MenuItem,
  PaginationProps,
  Popup,
  Progress,
  Table,
} from "semantic-ui-react";
import LoadingAnimation from "../../../common/Loader";
import { StyledGridColumn } from "../../DeviceManagement/Devices/Device";
import { ActionButton } from "../../DeviceManagement/Devices/ActionButtons";
import { ActivateDeviceConfirmationModal } from "../../DeviceManagement/Devices/ActionModals/ActivateDeviceConfirmationModal";
import { ShowDeviceStreamsModal } from "../../DeviceManagement/Devices/ActionModals/ShowDeviceStreamsModal";
import { ShowConfigVersionModal } from "../../DeviceManagement/Devices/ActionModals/ShowConfigVersionModal";
import { ShowDeviceDashboardModal } from "../../DeviceManagement/Devices/ActionModals/ShowDeviceDashboardModal";
import { RemoteShellModal } from "../../DeviceManagement/Devices/ActionModals/RemoteShellModal";
import { useUser } from "../../../../context/User.context";
import { ActionsMenuWrapper } from "../ActionsV3/ActionsV3";
// import ActionsMenuTab from "./ActionsMenuTab";
import { StyledNonBoldHeader } from "../NewAction/NewAction";
import ActionV3Button from "../ActionsV3/ActionButton";
import ActionFilterDropdown from "../ActionsV3/ActionFilterDropdown";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { SelectDevicesPerPage } from "../../DeviceManagement/Devices/Devices";
// import ThemeSchema from "../../../../theme/schema";
// import ActionsHistoricalBarChart from "./ActionsHistoricalBarChart";
import {
  StyledCardSearchPageInput,
  StyledPagination,
  StyledSecondaryDevicePerPageWidget,
} from "../../../common/commonStyledComps";
import ConfirmationModal from "../../common/ConfirmationModal";
import ConfirmationModalMessageNonDelete from "../../common/ConfirmationModalMessageNonDelete";
import { beamtoast } from "../../../common/CustomToast";
import { CardContainer, FlexContainer } from "../../../common/ActionsUtils";
import {
  OperationsButtonIcon,
  checkLogs,
  convertDecimalToRoman,
} from "../util";
import Toggle from "../../../common/Toggle";
import { useHistory, useRouteMatch } from "react-router-dom";
import ViewPayloadModal from "./ViewPayloadModal";
// import Toggle from "../../../common/Toggle";

interface DeviceV3OverviewProps {
  actionIDParam: string | null;
  deviceIDParam: string | null;
}

type DeviceActionType = {
  action_id: number;
  created_at: string;
  device_id: string;
  errors: string[];
  params: string;
  phase: number;
  progress: number;
  status: string;
  type: string;
  updated_at: string;
  user_email: string;
  user_name: string;
  payload_type?: string;
};

const DeviceOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 60px;
`;

const ErrorTableCell = styled(Table.Cell)`
  max-width: 50px;
  white-space: normal;
  word-wrap: break-word;
`;

const AuditActionTable = styled(Table)`
  & tbody tr {
    td {
      padding: 2rem 1rem !important;
    }
  }
`;

const AuditActionTableRow = styled(Table.Row)`
  cursor: pointer;
  &.selected {
    background-color: ${({ theme }) =>
      theme.colors["action-selected-table-background"]};
    color: ${({ theme }) => theme.colors["action-selected-table-text-color"]};
  }
`;

const dummyHistoricalMetadataData = [
  {
    id: 1,
    details: "Location tag changed to Bangalore",
    user: "Mock User",
    actionTime: "3 days ago",
    errors: "Action Timeout",
  },
];

// const dummyHistoricalActionData = [
//   {
//     action_id: 2954,
//     device_id: "2",
//     errors: [],
//     params:
//       '{"content-length":227862,"status":false,"url":"https://firmware.stage.bytebeam.io/api/v1/firmwares/new62/artifact","version":"new62"}',
//     progress: 0,
//     status: "Completed",
//     type: "update_firmware",
//     updated_at: "2024-01-08T06:48:39Z",
//   },
// ];

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  header: string;
  content: string;
}

export default function DeviceV3Overview(props: DeviceV3OverviewProps) {
  const { user } = useUser();
  const serial_metadata = user?.["tenant-settings"]?.["serial-key"] ?? "";

  const history = useHistory();
  const { path } = useRouteMatch();
  let abortController = new AbortController();

  const [loading, setLoading] = useState<boolean>(true);
  const [device, setDevice] = useState<Device>();

  const [streams, setStreams] = useState<Record<string, string[]>>({});
  const [dashboards, setDashboards] = useState<any[]>();

  const [deviceStatus, setDeviceStatus] = useState<string>();
  const [permissions, setPermissions] = useState<Permission>();
  const [metadataKeys, setMetadataKeys] = useState<string[]>();
  const [editableMetadataKeys, setEditableMetadataKeys] =
    useState<Set<string>>();
  const [deviceID, setDeviceID] = useState<number>();
  const [lastAction, setLastAction] = useState<LastActionType>();
  const [allowedActions, setAllowedActions] = useState<string[] | "all">();
  const [stateKeysNotUniq, setStateKeysNotUniq] = useState<string[]>();
  const [stateKeys, setStateKeys] = useState<string[]>();

  const [showConfigVersion, setShowConfigVersion] = useState<boolean>(false);
  const [remoteShellDeviceID, setRemoteShellDeviceID] = useState<number>(0);

  const [openActivateConfirmationModal, setOpenActivateConfirmationModal] =
    useState<boolean>(false);
  const [openShowStreamsModal, setOpenShowStreamsModal] =
    useState<boolean>(false);
  const [openShowConfigVersionModal, setOpenShowConfigVersionModal] =
    useState<boolean>(false);
  const [openShowDeviceDashboardModal, setOpenShowDeviceDashboardModal] =
    useState<boolean>(false);

  const [historicalActions, setHistoricalActions] = useState<
    DeviceActionType[]
  >([]);
  const [historicalActionPage, setHistoricalActionPage] = useState<
    DeviceActionType[]
  >([]);
  const [historicalActionPageNumber, setHistoricalActionPageNumber] =
    useState<number>(1);
  const [historicalActionPageLimit] = useState<number>(25);
  const [historicalActionTotalPage, setHistoricalActionTotalPage] =
    useState<number>(1);
  const [historicalActionLoading, setHistoricalActionLoading] =
    useState<Boolean>(false);

  const [activeItem, setActiveItem] = useState<string>("actions");
  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const [showIncomplete, setShowIncomplete] = useState<boolean>(false);
  const [showPayload, setShowPayload] = useState(false);
  const [selectedAction, setSelectedAction] = useState<DeviceActionType>(
    historicalActionPage[0]
  );
  const [sortColumn, setSortColumn] = useState<string | null>("action_id");
  const [sortDirection, setSortDirection] = useState<
    "descending" | "ascending" | undefined
  >("descending");

  const [detailedStreamsList, setDetailedStreamsList] =
    useState<FetchStreamsAPIResponse>({});

  const sortData = (data, column, direction) => {
    if (!column) return data; // No sorting if no column is set
    return [...data].sort((a, b) => {
      // Check if the values are strings; if so, convert to lowercase for comparison.
      // Otherwise, use the value as is.
      const valA =
        typeof a[column] === "string" ? a[column].toLowerCase() : a[column];
      const valB =
        typeof b[column] === "string" ? b[column].toLowerCase() : b[column];

      if (valA < valB) {
        return direction === "ascending" ? -1 : 1;
      }
      if (valA > valB) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0; // if equal
    });
  };

  const [auditFilterOptions, setAuditFilterOptions] = useState<any[]>([
    {
      id: 2,
      name: "Completed",
      icon: "check circle",
    },
    {
      id: 3,
      name: "Initiated",
      icon: "bars",
    },
    {
      id: 4,
      name: "In-Progress",
      icon: "bars",
    },
    {
      id: 5,
      name: "Queued",
      icon: "bars",
    },
  ]);

  const [selectedAuditFilterOptions, setSelectedAuditFilterOptions] = useState<
    any[]
  >([
    {
      id: 1,
      name: "Failed",
      icon: "close icon",
    },
  ]);

  const [theme, setTheme] = useState<any>();

  const StyledDeviceOperationsButton = {
    background: theme === "light" ? "#ffffff" : "transparent",
    color: theme === "light" ? "#5C5C5C;" : "#C1C1C1",
    border: `1px solid ${theme === "light" ? "#828282" : "#C1C1C1"}`,
    marginRight: "12px",
    padding: "10px 12px",
    borderRadius: "4px",
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleItemClick = (item: string) => {
    setActiveItem(item);
  };

  const getStreamList = async () => {
    try {
      const res = filterTableInfo(await fetchTableInfo());
      setStreams(res);
    } catch (e) {
      console.log(e);
    }
  };

  const getDashboardList = async () => {
    try {
      const res = await fetchAllDashboards();
      setDashboards(res);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchSpecificDevice = async () => {
    abortController.abort();
    abortController = new AbortController();
    if (props.deviceIDParam && props.actionIDParam)
      await fetchDeviceWithSpecificAction(
        parseInt(props.deviceIDParam),
        parseInt(props.actionIDParam),
        abortController.signal
      ).then((deviceData) => {
        setDevice(deviceData);
        setPermissions(user?.role?.permissions);
        setMetadataKeys(user?.role?.permissions?.viewMetadata as string[]);
        setEditableMetadataKeys(
          new Set(user?.role?.permissions?.editMetadata || [])
        );

        setDeviceStatus(deviceData["status"]);

        setDeviceID(deviceData["id"]);
        setLastAction(deviceData["action"]);

        // Choosing not to show sequence in UI table, even though data is available
        // via API.
        setStateKeysNotUniq(
          (user?.role?.permissions?.tables["device_shadow"] as string[]).filter(
            (key: string) => key !== "sequence"
          )
        );
        setStateKeys(
          _.uniq(
            (
              user?.role?.permissions?.tables["device_shadow"] as string[]
            ).filter((key: string) => key !== "sequence")
          )
        );

        setAllowedActions(user?.role?.permissions?.allowedActions);
        setShowConfigVersion(
          _.uniq(
            (
              user?.role?.permissions?.tables["device_shadow"] as string[]
            ).filter((key: string) => key !== "sequence")
          ).includes("config_version")
        );

        setTheme(user?.settings?.theme ?? "dark");
        setLoading(false);
        fetchAllDeviceActionsFn(deviceData["id"], showIncomplete);
      });
  };

  const toggleSortDirection = (currentDirection) =>
    currentDirection === "ascending" ? "descending" : "ascending";

  const onHeaderClick = (newSortColumn) => {
    const isAsc = sortColumn === newSortColumn && sortDirection === "ascending";
    const newSortDirection = toggleSortDirection(sortDirection);

    // Update state
    setSortColumn(newSortColumn);
    setSortDirection(isAsc ? "descending" : "ascending");

    // Fetch new sorted data with the immediate new sorting parameters
    fetchAllDeviceActionsFn(
      deviceID,
      showIncomplete,
      newSortColumn,
      newSortDirection
    );
  };

  // Adjust fetchAllDeviceActionsFn to accept new parameters for sorting
  const fetchAllDeviceActionsFn = async (
    deviceID,
    incomplete,
    sortColumn = "action_id",
    sortDirection = "descending"
  ) => {
    try {
      if (deviceID) {
        setHistoricalActionLoading(true);
        const res = await fetchAllDeviceActions(deviceID, incomplete);
        // Use the new sortColumn and sortDirection parameters directly for sorting
        const sortedHistoricalData = sortData(
          res?.device_actions,
          sortColumn,
          sortDirection
        );
        setHistoricalActions(sortedHistoricalData);
        setHistoricalActionLoading(false);
        setHistoricalActionPage(
          sortedHistoricalData.slice(0, historicalActionPageLimit)
        );
        setHistoricalActionPageNumber(1);
        setHistoricalActionTotalPage(
          Math.ceil(sortedHistoricalData.length / historicalActionPageLimit)
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const fetchAllDeviceActionsFn = async (deviceID, incomplete) => {
  //   try {
  //     if (deviceID) {
  //       setHistoricalActionLoading(true);
  //       const res = await fetchAllDeviceActions(deviceID, incomplete);
  //       const sortedHistoricalData = await sortData(
  //         res?.device_actions,
  //         sortColumn,
  //         sortDirection
  //       );
  //       setHistoricalActions(sortedHistoricalData);
  //       setHistoricalActionLoading(false);
  //       setHistoricalActionPage(
  //         sortedHistoricalData.slice(0, historicalActionPageLimit)
  //       );
  //       setHistoricalActionPageNumber(1);
  //       setHistoricalActionTotalPage(
  //         Math.ceil(sortedHistoricalData.length / historicalActionPageLimit)
  //       );
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const handleConfirmMarkAllComplete = () => {
    // call the function that marks all device actions as complete
    handleMarkAllDeviceActions(deviceID);
  };

  const handleMarkAllDeviceActions = async (deviceID) => {
    try {
      if (deviceID) {
        let res = await completeAllActionsForDevices({
          id: [deviceID],
        });
        if (res && res?.count !== undefined) {
          beamtoast.success(`Marked all actions as complete`);
        }
        await fetchAllDeviceActionsFn(deviceID, showIncomplete);
      }
    } catch (e) {
      console.log(e);
      beamtoast.error("Failed to mark all actions as complete.");
    }
  };

  const changePageForActions = (pageNumber: number) => {
    let newData = historicalActions.slice(
      (pageNumber - 1) * historicalActionPageLimit,
      pageNumber * historicalActionPageLimit
    );
    console.log(historicalActionPageLimit);
    setHistoricalActionPage(newData);
  };

  const downloadFile = async (data: any, key: string) => {
    const element = document.createElement("a");
    const file = new Blob([data], {
      type: "text/plain",
    });

    element.href = URL.createObjectURL(file);
    element.download = key;
    document.body.appendChild(element);

    setTimeout(() => element.click());
  };

  const downloadCertificatesFn = async (id: number) => {
    const res = await downloadCertificates(id);
    await downloadFile(JSON.stringify(res), `device_${id}.json`);
  };

  const setRemoteShellDeviceId = (deviceId: number) => {
    setRemoteShellDeviceID(deviceId);
  };

  const changeDeviceStatusFn = async (id: number, status: string) => {
    try {
      setLoading(true);
      await changeDeviceStatus(id, status);
      await fetchSpecificDevice();
      beamtoast.success(`Device status set to ${status}`);
    } catch (e) {
      beamtoast.error("Failed to change device status");
      console.error("Error in changeDeviceStatus: ", e);
    }
  };

  const addSelectedFilter = (selectedItem: any) => {
    setSelectedAuditFilterOptions([
      ...selectedAuditFilterOptions,
      selectedItem,
    ]);

    setAuditFilterOptions((auditFilterOptions) =>
      auditFilterOptions
        .filter((option) => option.id !== selectedItem.id)
        .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
    );
  };

  const onHistoricalActionPageChange = (
    event: MouseEvent,
    data: PaginationProps
  ) => {
    const activePage = data.activePage as number;
    setHistoricalActionPageNumber(activePage);
    changePageForActions(activePage);
  };

  const handleMarkAction = async (actionID: string, deviceID?: number) => {
    try {
      if (actionID) {
        // If deviceID is not provided, mark all actions as complete and if deviceID is provided, mark only that action as complete.
        await markActionAsCompleted(
          actionID,
          deviceID ? { metadata: { id: [String(deviceID)] } } : { all: true }
        );
        beamtoast.success(
          `Marked action ${actionID} as completed ${deviceID ? `for device(id: ${deviceID})` : "for all devices"}`
        );
        await fetchSpecificDevice();
      }
    } catch (e) {
      console.log(e);
      beamtoast.error(`Failed to mark action ${actionID} as completed`);
    }
  };

  const handleConfirmMarkComplete = (actionId: string, deviceID?: number) => {
    if (user.role.permissions.allowMarkActionAsCompleted)
      handleMarkAction(actionId, deviceID);
  };

  useEffect(() => {
    if (user.id !== "") {
      getStreamList();
      getDashboardList();
      fetchSpecificDevice();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function getDetailedStreamList() {
      try {
        const res = await fetchAllStreamsWithDetails();
        setDetailedStreamsList(res);
      } catch (e) {
        console.log(e);
      }
    }

    getDetailedStreamList();
  }, []);

  return device && !loading ? (
    <DeviceOverviewContainer>
      {deviceID && deviceStatus && streams && dashboards && (
        <>
          <ActivateDeviceConfirmationModal
            deviceId={deviceID}
            deviceStatus={deviceStatus}
            changeDeviceStatus={changeDeviceStatusFn}
            isOpen={openActivateConfirmationModal}
            close={() => setOpenActivateConfirmationModal(false)}
          />
          <ShowDeviceStreamsModal
            deviceId={deviceID}
            isOpen={openShowStreamsModal}
            close={() => setOpenShowStreamsModal(false)}
            theme={theme}
            streams={streams}
            detailedStreamsList={detailedStreamsList}
          />
          <ShowConfigVersionModal
            configVersion={device["state"]["config_version"]}
            isOpen={openShowConfigVersionModal}
            close={() => setOpenShowConfigVersionModal(false)}
            theme={theme}
          />
          <ShowDeviceDashboardModal
            deviceId={deviceID}
            isOpen={openShowDeviceDashboardModal}
            dashboards={dashboards}
            close={() => setOpenShowDeviceDashboardModal(false)}
          />
        </>
      )}

      {remoteShellDeviceID ? (
        <RemoteShellModal
          deviceId={remoteShellDeviceID}
          isOpen={remoteShellDeviceID > 0}
          close={() => setRemoteShellDeviceID(-1)}
        />
      ) : (
        <></>
      )}

      <CardContainer marginBottom="25px">
        <h1
          style={{
            fontSize: "32px",
            fontWeight: 700,
            textAlign: "left",
            marginBottom: "32px",
          }}
        >
          Device Overview
        </h1>

        <StyledHeader
          as="h3"
          style={{
            marginTop: "0px",
            marginBottom: "20px",
            width: "fit-content",
          }}
        >
          {serial_metadata ? (
            <Popup
              position="top center"
              inverted
              trigger={
                <div>
                  {`#${capitalizeFirstLetter(serial_metadata)}: ${device?.["-serial_metadata"]?.[serial_metadata]}`}
                </div>
              }
              content={`Id: ${deviceID}`}
            />
          ) : (
            `Device ID: ${deviceID}`
          )}
        </StyledHeader>

        {device &&
          permissions &&
          lastAction &&
          metadataKeys &&
          editableMetadataKeys &&
          deviceID &&
          stateKeys &&
          stateKeysNotUniq && (
            <Grid columns={3} divided>
              <DeviceOverview
                stateKeys={stateKeys}
                device={device}
                deviceId={deviceID}
                lastAction={lastAction}
                metadataKeys={metadataKeys}
                enablePinMetadata={false}
                editableMetadataKeys={editableMetadataKeys}
                specificAction
              />
            </Grid>
          )}

        <StyledHeader
          as="h3"
          style={{ marginTop: "40px", marginBottom: "20px" }}
        >
          Operations
        </StyledHeader>

        <Grid>
          <StyledGridColumn style={{ justifyContent: "flex-start" }} width={16}>
            <ActionButton
              onClick={() => {
                if (deviceStatus === "active" && deviceID)
                  downloadCertificatesFn(deviceID);
                else {
                  beamtoast.error(
                    "Cannot download the configuration for a deactivated device."
                  );
                }
              }}
              style={StyledDeviceOperationsButton}
            >
              <Icon name="download" /> <span>Download Config</span>
            </ActionButton>

            {allowedActions?.includes("launch_shell") && (
              <ActionButton
                onClick={() => {
                  if (deviceID) setRemoteShellDeviceId(deviceID);
                }}
                style={StyledDeviceOperationsButton}
              >
                <Icon name="terminal" /> <span>Remote Shell</span>
              </ActionButton>
            )}
            {/* Show only if config_version is in device_shadow */}
            {showConfigVersion && (
              <ActionButton
                onClick={() => setOpenShowConfigVersionModal(true)}
                style={StyledDeviceOperationsButton}
              >
                <Icon name="configure" /> <span>Config Version</span>
              </ActionButton>
            )}
            {Object.keys(user?.role?.permissions?.tables).length > 0 && (
              <ActionButton
                onClick={() => setOpenShowStreamsModal(true)}
                style={StyledDeviceOperationsButton}
              >
                <Icon name="table" /> <span>Streams</span>
              </ActionButton>
            )}
            {Object.entries(streams).filter(([stream, fields]) =>
              checkLogs(stream)
            ).length > 0 && (
              <ActionButton
                onClick={() =>
                  window.open(
                    `/projects/${getTenantFromURL()}/actions/logs/device-log?device_id=${deviceID}`,
                    "_blank"
                  )
                }
                style={StyledDeviceOperationsButton}
              >
                <Icon name="file alternate outline" /> <span>Logs</span>
              </ActionButton>
            )}
            <ActionButton
              onClick={() => setOpenShowDeviceDashboardModal(true)}
              style={StyledDeviceOperationsButton}
            >
              <Icon name="dashboard" /> <span>Device Dashboards</span>
            </ActionButton>
            <ActionButton
              onClick={() => setOpenActivateConfirmationModal(true)}
              style={StyledDeviceOperationsButton}
            >
              <Icon
                name={
                  deviceStatus === "active" ? "ban" : "check circle outline"
                }
              />{" "}
              <span>
                {deviceStatus === "active" ? "Deactivate" : "Activate"}
              </span>
            </ActionButton>
          </StyledGridColumn>
        </Grid>
      </CardContainer>

      <CardContainer>
        <h1
          style={{
            fontSize: "32px",
            fontWeight: 700,
            textAlign: "left",
            marginBottom: "2px",
          }}
        >
          Historical Actions
        </h1>

        <ActionsMenuWrapper className="desktop-view">
          {/* <ActionsMenuTab
            firstElement
            name={"Actions"}
            icon={"clock"}
            displayIf={true}
            active={activeItem === "actions"}
            secondaryBackground
            onClick={() => {
              handleItemClick("actions");
            }}
          /> */}

          {/* <ActionsMenuTab
            name={"Metadata"}
            icon={"file code"}
            displayIf={true}
            active={activeItem === "metadata"}
            secondaryBackground
            onClick={() => {
              handleItemClick("metadata");
            }}
            lastElement
          /> */}
        </ActionsMenuWrapper>

        {activeItem === "metadata" ? (
          <>
            <FlexContainer style={{ marginTop: "30px", marginBottom: "30px" }}>
              <StyledNonBoldHeader as="h3" style={{ marginTop: "5px" }}>
                Filter By
              </StyledNonBoldHeader>

              <div style={{ position: "relative" }}>
                <ActionV3Button
                  onClick={() => {
                    setShowFilterDropdown(true);
                  }}
                  label={"Add"}
                  margin_left={"20px"}
                  padding={"0.5rem 1rem"}
                  icon={"plus"}
                />

                {showFilterDropdown && (
                  <ActionFilterDropdown
                    dropdownOptions={auditFilterOptions}
                    setShowFilterDropdown={(value) =>
                      setShowFilterDropdown(value)
                    }
                    addFilter={(selectedItem) => {
                      addSelectedFilter(selectedItem);
                    }}
                  />
                )}
              </div>
            </FlexContainer>

            <Grid style={{ marginBottom: "20px" }}>
              <Grid.Row>
                <Grid.Column>
                  <AuditActionTable selectable>
                    <Table.Header>
                      <Table.Row textAlign={"center"}>
                        <Table.HeaderCell>Details</Table.HeaderCell>
                        <Table.HeaderCell>User</Table.HeaderCell>
                        <Table.HeaderCell>Action Time</Table.HeaderCell>
                        <Table.HeaderCell width={4}>Errors</Table.HeaderCell>
                        <Table.HeaderCell>Progress</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {dummyHistoricalMetadataData.length !== 0 ? (
                        dummyHistoricalMetadataData.map((metadata, key) => (
                          <AuditActionTableRow
                            textAlign={"center"}
                            key={key}
                            // if selection is there then use below:
                            // className={selectedAction === action ? "selected" : ""}
                            // onClick={() => {}}
                          >
                            <Table.Cell>{metadata.details}</Table.Cell>
                            <Table.Cell>
                              {metadata.user ? (
                                <Popup
                                  content={"mockuser@bytebeam.io"}
                                  position="top center"
                                  inverted
                                  trigger={<div>{metadata.user}</div>}
                                />
                              ) : (
                                <div>{metadata.user}</div>
                              )}
                            </Table.Cell>
                            <Table.Cell>{metadata.actionTime}</Table.Cell>
                            <ErrorTableCell width={4}>
                              {metadata.errors}
                            </ErrorTableCell>
                            <Table.Cell>
                              {/* dummy progress bar for now */}
                              <Progress
                                progress="percent"
                                percent={
                                  // styleActionProgress(data?.status, data?.progress)[
                                  //   "progress"
                                  // ]
                                  80
                                }
                                indicating={
                                  // data?.status === "Completed" ||
                                  // data?.status === "Failed"
                                  //   ? false
                                  //   : true
                                  true
                                }
                                size="medium"
                                style={{ marginBottom: "0" }}
                                // className={`action-progress progress-margin ${
                                //   styleActionProgress(data?.status, data?.progress)[
                                //     "className"
                                //   ]
                                // }`}
                              />
                            </Table.Cell>
                          </AuditActionTableRow>
                        ))
                      ) : (
                        <Table.Row>
                          <Table.Cell colSpan={"7"}>
                            <div
                              style={{
                                height: "60vh",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <ErrorMessage
                                marginTop="30px"
                                message={"No metadata found!"}
                              />
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </AuditActionTable>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {!loading && dummyHistoricalMetadataData?.length !== 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "nowrap",
                  gap: "16px",
                }}
              >
                <StyledPagination
                  boundaryRange={0}
                  ellipsisItem={null}
                  siblingRange={2}
                  activePage={
                    // actionsPageNumber
                    1
                  }
                  totalPages={
                    // Math.ceil(
                    // totalActionsForPagination / actionsPageLimit)
                    10
                  }
                  onPageChange={() => {}}
                />

                <StyledCardSearchPageInput
                  icon="search"
                  placeholder="Jump to page..."
                  name="activePage"
                  min={1}
                  onChange={() => {}}
                  onKeyDown={() => {}}
                  type="number"
                  value={
                    // inputPageNumber ? inputPageNumber : ""
                    ""
                  }
                />

                <StyledSecondaryDevicePerPageWidget>
                  <MenuItem>Metadata per page</MenuItem>
                  <MenuItem style={{ padding: "0px" }}>
                    <SelectDevicesPerPage
                      compact
                      selection
                      options={
                        // devicesPerPageOptions
                        [5, 10, 50, 100]
                      }
                      value={
                        // actionsPageLimit
                        5
                      }
                      onChange={() => {}}
                    />
                  </MenuItem>
                </StyledSecondaryDevicePerPageWidget>
              </div>
            )}
          </>
        ) : activeItem === "actions" ? (
          <>
            {/* <FlexContainer style={{ marginTop: "30px", marginBottom: "30px" }}>
              <StyledNonBoldHeader as="h3" style={{ marginTop: "5px" }}>
                Filter By
              </StyledNonBoldHeader>

              {selectedAuditFilterOptions.flatMap((selectedOption) => (
                <SelectedFilter
                  name={selectedOption.name}
                  icon={selectedOption.icon}
                  onClick={() => {
                    removeSelectedFilter(selectedOption);
                  }}
                />
              ))}

              <div style={{ position: "relative" }}>
                <ActionV3Button
                  onClick={() => {
                    setShowFilterDropdown(true);
                  }}
                  label={"Add"}
                  marginLeft={"20px"}
                  padding={"0.5rem 1rem"}
                  icon={"plus"}
                />

                {showFilterDropdown && (
                  <ActionFilterDropdown
                    dropdownOptions={auditFilterOptions}
                    setShowFilterDropdown={(value) =>
                      setShowFilterDropdown(value)
                    }
                    addFilter={(selectedItem) => {
                      addSelectedFilter(selectedItem);
                    }}
                  />
                )}
              </div>
            </FlexContainer> */}
            <div
              style={{
                marginTop: "16px",
                marginBottom: "26px",
                display: "flex",
                // flexDirection: "row",
                justifyContent: "space-between",
                gap: "16px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <div>Show Incomplete Actions</div>
                <div>
                  <Toggle
                    id="sortByIncompleteActions"
                    checked={showIncomplete}
                    onChange={() => {
                      fetchAllDeviceActionsFn(deviceID, !showIncomplete);
                      setShowIncomplete(!showIncomplete);
                    }}
                    style={{
                      top: "4px",
                    }}
                  />
                </div>
              </div>
              {!historicalActionLoading &&
                historicalActionPage.length > 0 &&
                permissions?.allowMarkActionAsCompleted && (
                  <div>
                    <ConfirmationModal
                      prefixContent="Mark All Actions as Complete"
                      expectedText={"Yes"}
                      onConfirm={handleConfirmMarkAllComplete}
                      trigger={
                        <ActionV3Button
                          onClick={() => {}}
                          label={"Mark All Complete"}
                          margin_left={"20px"}
                          padding={"0.5rem 1rem"}
                          icon={"check circle"}
                        />
                      }
                      message={
                        <ConfirmationModalMessageNonDelete
                          name={"Mark All Actions as Complete"}
                          expectedText={"Yes"}
                          type={""}
                          specialMessage={`This will mark all Actions triggered on this Device(${serial_metadata ? `${serial_metadata}: ${device?.["-serial_metadata"]?.[serial_metadata]}` : `id: ${deviceID}`}) which are not completed/failed as completed.`}
                        />
                      }
                    />
                  </div>
                )}
            </div>
            <Grid style={{ marginBottom: "20px" }}>
              <ViewPayloadModal
                isOpen={showPayload}
                onClose={() => setShowPayload(false)}
                actionId={selectedAction?.action_id as number}
              />
              <Grid.Row>
                <Grid.Column>
                  <AuditActionTable
                    id="device_historical_actions-list"
                    selectable
                    sortable
                  >
                    <Table.Header>
                      <Table.Row textAlign={"center"}>
                        {/* <Table.HeaderCell>ID</Table.HeaderCell> */}
                        <Table.HeaderCell
                          sorted={
                            sortColumn === "action_id"
                              ? sortDirection
                              : undefined
                          }
                          onClick={() => onHeaderClick("action_id")}
                        >
                          ID
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={
                            sortColumn === "type" ? sortDirection : undefined
                          }
                          onClick={() => onHeaderClick("type")}
                        >
                          Action Type
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={
                            sortColumn === "user_name"
                              ? sortDirection
                              : undefined
                          }
                          onClick={() => onHeaderClick("user_name")}
                        >
                          Created By
                        </Table.HeaderCell>
                        {/* <Table.HeaderCell
                          sorted={
                            sortColumn === "created_at"
                              ? sortDirection
                              : undefined
                          }
                          onClick={() => onHeaderClick("created_at")}
                        >
                          Created at
                        </Table.HeaderCell> */}
                        <Table.HeaderCell
                          sorted={
                            sortColumn === "updated_at"
                              ? sortDirection
                              : undefined
                          }
                          onClick={() => onHeaderClick("updated_at")}
                        >
                          Timestamps
                        </Table.HeaderCell>
                        {/* <Table.HeaderCell
                          sorted={sortColumn === "type" ? sortDirection : undefined}
                          onClick={() => {
                            let isAsc = sortColumn === 'type' && sortDirection === 'ascending';
                            setSortColumn('type');
                            setSortDirection(isAsc ? 'descending' : 'ascending');
                            fetchAllDeviceActionsFn(deviceID, showIncomplete);
                          }}
                        >Approved By</Table.HeaderCell> */}
                        <Table.HeaderCell
                          sorted={
                            sortColumn === "status" ? sortDirection : undefined
                          }
                          onClick={() => onHeaderClick("status")}
                        >
                          Last Status
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={
                            sortColumn === "errors" ? sortDirection : undefined
                          }
                          onClick={() => onHeaderClick("errors")}
                          width={4}
                        >
                          Errors
                        </Table.HeaderCell>
                        <Table.HeaderCell>Operations</Table.HeaderCell>
                        {/* <Table.HeaderCell
                          sorted={sortColumn === "type" ? sortDirection : undefined}
                          onClick={() => {
                            let isAsc = sortColumn === 'type' && sortDirection === 'ascending';
                            setSortColumn('type');
                            setSortDirection(isAsc ? 'descending' : 'ascending');
                            fetchAllDeviceActionsFn(deviceID, showIncomplete);
                          }}
                        >Progress</Table.HeaderCell> */}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {!historicalActionLoading &&
                      historicalActionPage.length > 0 ? (
                        historicalActionPage.map((action, key) => (
                          <AuditActionTableRow
                            textAlign={"center"}
                            key={key}
                            // if selection is there then use below:
                            // className={selectedAction === action ? "selected" : ""}
                            // onClick={() => {}}
                          >
                            <Table.Cell>
                              <div>{`${action.action_id}`}</div>
                              <div>
                                {action.phase !== -1 &&
                                  `Phase ${convertDecimalToRoman(action?.phase + 1)}`}
                              </div>
                            </Table.Cell>
                            <Table.Cell>{action.type}</Table.Cell>
                            <Table.Cell>
                              {action.user_email ? (
                                <Popup
                                  content={action.user_email}
                                  position="top center"
                                  inverted
                                  trigger={<div>{action.user_name}</div>}
                                />
                              ) : (
                                <div>{action.user_name}</div>
                              )}
                            </Table.Cell>
                            {/* <Table.Cell>
                              {new Date(action.created_at)?.toLocaleString(
                                "en-GB"
                              )}
                            </Table.Cell> */}
                            <Table.Cell>
                              <div>
                                Created At:{" "}
                                {new Date(action.created_at)?.toLocaleString(
                                  "en-GB"
                                )}
                              </div>
                              <div>
                                Updated At:{" "}
                                {new Date(action.updated_at)?.toLocaleString(
                                  "en-GB"
                                )}
                              </div>
                            </Table.Cell>
                            <Table.Cell>{action.status}</Table.Cell>
                            <Table.Cell width={4}>
                              {action.errors && action.errors.length > 0
                                ? action.errors
                                : `N/A`}
                            </Table.Cell>
                            <Table.Cell>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                }}
                              >
                                <OperationsButtonIcon
                                  disabled={
                                    action?.payload_type === "none" &&
                                    action.type !== "send_file" &&
                                    action.type !== "send_script"
                                  }
                                  name="file code outline"
                                  title="View Payload"
                                  onClick={() => {
                                    setSelectedAction(action);
                                    setShowPayload(true);
                                  }}
                                />
                                {user.role.permissions
                                  .allowMarkActionAsCompleted && (
                                  <ConfirmationModal
                                    prefixContent="Mark Device Action as Complete"
                                    expectedText={String(action.action_id)}
                                    onConfirm={() => {
                                      handleConfirmMarkComplete(
                                        String(action.action_id),
                                        deviceID
                                      );
                                    }}
                                    trigger={
                                      <OperationsButtonIcon
                                        disabled={
                                          ["completed", "failed"].includes(
                                            action.status.toLowerCase()
                                          ) || action?.type === "cancel_action"
                                        }
                                        name="check circle outline"
                                        title="Mark Action on device as completed"
                                      />
                                    }
                                    message={
                                      <ConfirmationModalMessageNonDelete
                                        name={"Mark Device Action as Complete"}
                                        expectedText={String(action.action_id)}
                                        type={""}
                                        specialMessage={`This will mark the Action(id: ${action.action_id}, type: ${action.type}) for Device(id: ${deviceID}) as completed.`}
                                      />
                                    }
                                  />
                                )}
                                {user.role.permissions
                                  .allowMarkActionAsCompleted && (
                                  <ConfirmationModal
                                    prefixContent="Mark Action as Complete"
                                    expectedText={String(action.action_id)}
                                    onConfirm={() => {
                                      handleConfirmMarkComplete(
                                        String(action.action_id)
                                      );
                                    }}
                                    trigger={
                                      <OperationsButtonIcon
                                        disabled={
                                          action?.type === "cancel_action"
                                        }
                                        name="check square"
                                        title="Mark Action as completed"
                                      />
                                    }
                                    message={
                                      <ConfirmationModalMessageNonDelete
                                        name={"Mark Action as Complete"}
                                        expectedText={String(action.action_id)}
                                        type={""}
                                        specialMessage={`This will mark the Action(id: ${action.action_id}, type: ${action.type}) triggered on all Devices which are not completed/failed as completed.`}
                                      />
                                    }
                                  />
                                )}
                                <OperationsButtonIcon
                                  name="eye"
                                  title="More details"
                                  onClick={() => {
                                    history.push(
                                      `${path}?action_id=${action.action_id}`
                                    );
                                  }}
                                />
                              </div>
                            </Table.Cell>
                            {/* <Table.Cell>
                              <Popup
                                content={
                                  <div
                                    style={{
                                      padding: "2rem",
                                      borderRadius: "5px",
                                      border:
                                        ThemeSchema.data[theme ?? "dark"]
                                          ?.colors["container-border"],
                                      // border and border-radius doesn't work as of now. Need to figure out a workaround
                                    }}
                                  >
                                    <ActionsHistoricalBarChart
                                      setQueuedListCount={10}
                                      setInitiatedListCount={15}
                                      setDownloadingListCount={3}
                                      setInstallingListCount={9}
                                      setSendingFileListCount={34}
                                      setTotalCount={71}
                                      height={200}
                                      width={500}
                                      textInfo
                                    />
                                  </div>
                                }
                                position="left center"
                                inverted
                                trigger={
                                  <Progress
                                    progress="percent"
                                    percent={
                                      // styleActionProgress(data?.status, data?.progress)[
                                      //   "progress"
                                      // ]
                                      80
                                    }
                                    indicating={
                                      // data?.status === "Completed" ||
                                      // data?.status === "Failed"
                                      //   ? false
                                      //   : true
                                      true
                                    }
                                    size="medium"
                                    style={{ marginBottom: "0" }}
                                    // className={`action-progress progress-margin ${
                                    //   styleActionProgress(data?.status, data?.progress)[
                                    //     "className"
                                    //   ]
                                    // }`}
                                  />
                                }
                              />
                            </Table.Cell> */}
                            {/* <Table.Cell>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <OperationsButtonIcon
                                  name="file alternate outline"
                                  title="Operations logs"
                                  onClick={() => {
                                    // todo routing from here
                                    console.log("Device Ops btn clicked");
                                  }}
                                />
                              </div>
                            </Table.Cell> */}
                          </AuditActionTableRow>
                        ))
                      ) : (
                        <Table.Row>
                          <Table.Cell colSpan={"8"}>
                            <div
                              style={{
                                height: "60vh",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {historicalActionLoading ? (
                                <LoadingAnimation
                                  loaderContainerHeight="65vh"
                                  fontSize="1.5rem"
                                  loadingText="Loading Historical Actions"
                                />
                              ) : (
                                <ErrorMessage
                                  marginTop="30px"
                                  message={"No actions found! "}
                                />
                              )}
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </AuditActionTable>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {!loading &&
              !historicalActionLoading &&
              historicalActionPage?.length !== 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "nowrap",
                    gap: "16px",
                  }}
                >
                  <StyledPagination
                    boundaryRange={0}
                    ellipsisItem={null}
                    siblingRange={2}
                    activePage={historicalActionPageNumber}
                    totalPages={historicalActionTotalPage}
                    onPageChange={onHistoricalActionPageChange}
                  />

                  {/* <StyledCardSearchPageInput
                    icon="search"
                    placeholder="Jump to page..."
                    name="activePage"
                    min={1}
                    onChange={() => {}}
                    onKeyDown={() => {}}
                    type="number"
                    value={
                      // inputPageNumber ? inputPageNumber : ""
                      ""
                    }
                  /> */}
                  {/* 
                  <StyledSecondaryDevicePerPageWidget>
                    <MenuItem>Actions per page</MenuItem>
                    <MenuItem style={{ padding: "0px" }}>
                      <SelectDevicesPerPage
                        compact
                        selection
                        options={devicesPerPageOptions}
                        value={historicalActionPageLimit}
                        onChange={(e, data) => {
                          console.log(data.value);
                          let pageLimit = data?.value as number ?? 10;
                          console.log(pageLimit);
                          setHistoricalActionPageLimit(pageLimit);
                          onHistoricalActionPageChange(e, { activePage: 1 });
                        }}
                      />
                    </MenuItem>
                  </StyledSecondaryDevicePerPageWidget> */}
                </div>
              )}
          </>
        ) : (
          <div
            style={{
              height: "60vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ErrorMessage
              marginTop="30px"
              message={"No data found! Please try again."}
            />
          </div>
        )}
      </CardContainer>
    </DeviceOverviewContainer>
  ) : (
    <LoadingAnimation
      loaderContainerHeight="65vh"
      fontSize="1.5rem"
      loadingText="Loading device data"
    />
  );
}
