import React, { useEffect, useMemo, useState } from "react";
import { Accordion, Dropdown, Icon, Input, Popup } from "semantic-ui-react";
import { DateTimeInput } from "semantic-ui-calendar-react";
import moment from "moment";
import styled from "styled-components";
import {
  PhaseData,
  StyledNonBoldHeader,
  SuccessRateTriggerType,
  TimestampTriggerType,
} from "./NewAction";
import { ErrorMessage } from "../../Settings/roles/CreateOrEditRoleModal";
import Toggle from "../../../common/Toggle";
import { convertDecimalToRoman } from "../util";

type AdvancedPhasedSectionProps = {
  readonly phases: PhaseData[];
  readonly activePhase: string;
  readonly updateActivePhaseTimestamp: (arg0: any, arg1: string) => void;
  readonly updateActivePhaseFraction: (any, string) => void;
  readonly updateFailurePercentage: (
    value: number,
    phaseId: number,
    abortPhase: boolean
  ) => void;
  readonly phaseErrorsMessage: { [key: string]: string | null };
  readonly isPhasedRollout: boolean;
  readonly shouldTriggerImmediately: boolean;
  readonly setShouldTriggerImmediately: (
    shouldTriggerImmediately: boolean
  ) => void;
  readonly updateSuccessPercentage: (
    value: number,
    activePhase: string,
    isSuccessRate: boolean
  ) => void;
};

export const AdvancedSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
`;

export const AdvancedSectionItem = styled.div`
  display: flex;
  align-items: center;
`;

type TriggerPhaseType = "Immediate" | "Success Rate" | "Custom Time";

function AdvancedPhasedSection(props: AdvancedPhasedSectionProps) {
  const {
    phases,
    activePhase,
    updateActivePhaseTimestamp,
    updateSuccessPercentage,
    updateActivePhaseFraction,
    updateFailurePercentage,
    phaseErrorsMessage,
    isPhasedRollout,
    shouldTriggerImmediately,
    setShouldTriggerImmediately,
  } = props;

  const [abortPhase, setAbortPhase] = useState<boolean>(false);
  const [phaseTriggerType, setPhaseTriggerType] = useState<TriggerPhaseType>(
    activePhase === "Phase I" ? "Immediate" : "Success Rate"
  );

  const activePhaseData: PhaseData = useMemo(
    () => phases.filter((phase) => phase.name === activePhase)[0],
    // eslint-disable-next-line
    [phases, activePhase, isPhasedRollout]
  );

  const phasesWithTimestamp = phases.filter(
    (phase) => "timestamp" in phase.trigger_on
  );
  const prevPhaseWithTimestamp = phasesWithTimestamp.findLast(
    (phase) => phase.id <= activePhaseData.id - 1
  )?.trigger_on as TimestampTriggerType;
  const prevTimestamp = prevPhaseWithTimestamp?.timestamp;

  const showFractionInput = () => {
    if (activePhaseData?.info.type === "fixed-list" || !isPhasedRollout) {
      return false;
    }
    return (
      activePhaseData?.info.type === "filter-fraction" ||
      activePhaseData?.info.type === "filter-fraction-lazy"
    );
  };

  const errorMessage: string =
    phaseErrorsMessage?.[activePhaseData?.name] ?? "";

  function getPhaseTriggerTypeOptions() {
    let options = [
      {
        key: "Immediate",
        text: "Immediate",
        value: "Immediate",
      },
      {
        key: "Success Rate",
        text: "Success Rate",
        value: "Success Rate",
      },
      {
        key: "Custom Time",
        text: "Custom Time",
        value: "Custom Time",
      },
    ];

    if (activePhase === "Phase I") {
      options = options.filter((option) => option.value !== "Success Rate");
    } else {
      options = options.filter((option) => option.value !== "Immediate");
    }

    return options;
  }

  function handlePhaseTriggerTypeChange(
    _e: React.SyntheticEvent<HTMLElement>,
    data
  ) {
    setPhaseTriggerType(data.value as TriggerPhaseType);

    if (activePhase === "Phase I") {
      setShouldTriggerImmediately(data.value === "Immediate");
    }

    // Updating the phase data structure to reflect the change in trigger type
    if (data.value === "Success Rate") {
      updateSuccessPercentage(100, activePhaseData.name, true);
    } else if (data.value === "Immediate") {
      updateActivePhaseTimestamp(new Date(), activePhaseData.name);
    } else if (data.value === "Custom Time") {
      updateActivePhaseTimestamp(
        moment(prevTimestamp ?? new Date())
          .add(15, "minutes")
          .format("YYYY-MM-DD HH:mm:ss"),
        activePhaseData.name
      );
    }
  }

  useEffect(() => {
    if (activePhase === "Phase I" && shouldTriggerImmediately)
      setPhaseTriggerType("Immediate");
    else if ((activePhaseData.trigger_on as TimestampTriggerType).timestamp)
      setPhaseTriggerType("Custom Time");
    else setPhaseTriggerType("Success Rate");

    setAbortPhase(
      activePhaseData.trigger_on.hasOwnProperty("failure_percentage")
    );
  }, [activePhase]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AdvancedSectionContainer
      style={{
        alignItems: "flex-start",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Accordion fluid inverted>
        <Accordion.Title active={phaseTriggerType === "Success Rate"}>
          <AdvancedSectionContainer
            style={{
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <AdvancedSectionContainer>
              <StyledNonBoldHeader
                as="h3"
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                  fontSize: "1.1rem",
                  whiteSpace: "nowrap",
                }}
              >
                When to trigger
              </StyledNonBoldHeader>
              <Dropdown
                placeholder="Select"
                selection
                options={getPhaseTriggerTypeOptions()}
                value={phaseTriggerType}
                onChange={handlePhaseTriggerTypeChange}
              />
              {phaseTriggerType === "Custom Time" && (
                <DateTimeInput
                  minDate={moment(prevTimestamp ?? new Date())
                    .add(5, "minutes")
                    .format("YYYY-MM-DD HH:mm:ss")}
                  placeholder="Date Time"
                  key={activePhaseData?.name}
                  popupPosition="bottom right"
                  name="dateTime"
                  closable
                  hideMobileKeyboard
                  value={
                    (activePhaseData.trigger_on as TimestampTriggerType)
                      .timestamp
                      ? (
                          activePhaseData.trigger_on as TimestampTriggerType
                        ).timestamp?.toLocaleString("en-GB")
                      : ""
                  }
                  iconPosition="left"
                  dateTimeFormat={moment.defaultFormat}
                  preserveViewMode={false}
                  autoComplete="off"
                  onChange={(e, data) => {
                    e.preventDefault();
                    updateActivePhaseTimestamp(
                      data.value,
                      activePhaseData?.name
                    );
                  }}
                  closeOnMouseLeave={false}
                />
              )}
            </AdvancedSectionContainer>

            {showFractionInput() && (
              <AdvancedSectionContainer>
                <AdvancedSectionItem>
                  <StyledNonBoldHeader
                    as="h3"
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",
                      marginLeft: "48px",
                      fontSize: "1.1rem",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Enter Phase Percentage
                  </StyledNonBoldHeader>
                  <Popup
                    inverted
                    trigger={
                      <Icon
                        name="question circle"
                        style={{
                          marginBottom: "2px",
                          marginLeft: "8px",
                          cursor: "pointer",
                        }}
                      />
                    }
                    content={
                      "This percentage represents the portion of total devices allocated to this phase."
                    }
                    position="top center"
                  />
                </AdvancedSectionItem>
                <Input
                  key={activePhaseData?.name}
                  min={0}
                  max={100}
                  type="number"
                  inputMode="numeric"
                  icon={{ name: "percent" }}
                  disabled={activePhaseData?.info.type === "fixed-list"}
                  value={activePhaseData?.info?.fraction}
                  onChange={(_e, data) => {
                    updateActivePhaseFraction(
                      data.value,
                      activePhaseData?.name
                    );
                  }}
                  style={{
                    maxWidth: "96px",
                    minWidth: "96px",
                  }}
                />
              </AdvancedSectionContainer>
            )}
          </AdvancedSectionContainer>
        </Accordion.Title>
        <Accordion.Content active={phaseTriggerType === "Success Rate"}>
          <AdvancedSectionContainer style={{ gap: "16px" }}>
            <StyledNonBoldHeader
              as="h3"
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                marginLeft: "48px",
                fontSize: "1.1rem",
                whiteSpace: "nowrap",
              }}
            >
              {`Success Rate for Phase ${convertDecimalToRoman(activePhaseData?.id - 1)} with`}
            </StyledNonBoldHeader>
            <Dropdown
              selection
              button
              options={Array.from({ length: 10 }, (_, i) => (i + 1) * 10).map(
                (value) => ({
                  key: value,
                  text: `${value}%`,
                  value: value,
                })
              )}
              value={
                (activePhaseData.trigger_on as SuccessRateTriggerType)
                  .success_percentage
              }
              onChange={(_e, data) => {
                updateSuccessPercentage(
                  parseInt(data.value as string, 10),
                  activePhaseData.name,
                  true
                );
              }}
              style={{
                maxWidth: "84px",
                minWidth: "84px",
              }}
            />
          </AdvancedSectionContainer>
        </Accordion.Content>
      </Accordion>
      {activePhase !== "Phase I" && (
        <Accordion fluid inverted>
          <Accordion.Title active={abortPhase}>
            <AdvancedSectionContainer>
              <StyledNonBoldHeader
                as="h3"
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                  fontSize: "1.1rem",
                  whiteSpace: "nowrap",
                }}
              >
                Abort Phase
              </StyledNonBoldHeader>
              <Toggle
                id="abortPhaseToggle"
                checked={abortPhase}
                onChange={() => {
                  setAbortPhase(!abortPhase);
                  updateFailurePercentage(100, activePhaseData.id, !abortPhase);
                }}
              />
            </AdvancedSectionContainer>
          </Accordion.Title>

          <Accordion.Content active={abortPhase}>
            <Input
              min={1}
              max={100}
              type="number"
              inputMode="numeric"
              icon={{ name: "percent" }}
              label={{
                basic: true,
                content: "If Error in the previous phase is ",
              }}
              style={{ marginLeft: "16px", border: "none" }}
              value={activePhaseData.trigger_on.failure_percentage}
              onChange={(_e, data) => {
                updateFailurePercentage(
                  parseInt(data.value, 10),
                  activePhaseData.id,
                  abortPhase
                );
              }}
            />
          </Accordion.Content>
        </Accordion>
      )}

      {errorMessage && (
        <ErrorMessage
          style={{ fontWeight: 700, fontSize: "1rem", marginTop: "1.4rem" }}
        >
          {errorMessage}
        </ErrorMessage>
      )}
    </AdvancedSectionContainer>
  );
}

export default AdvancedPhasedSection;
