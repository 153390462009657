import React, { useMemo, useState } from "react";
import AceEditor from "react-ace";
import { Button, Dropdown, Modal } from "semantic-ui-react";
import styled from "styled-components";
import { useUser } from "../../../../context/User.context";
import ThemeSchema from "../../../../theme/schema";
import { beamtoast } from "../../../common/CustomToast";
import Toggle from "../../../common/Toggle";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-chaos";
import "ace-builds/src-noconflict/theme-chrome";
import {
  EditAnimatedMetaDropdown,
  EditAnimatedMetaInput,
} from "../../Dashboards/Panel/util";
import { StyledHeader } from "../ActionsV3/SelectableItem";
import { StyledNonBoldHeader, neverTimestamp } from "./NewAction";

const AdvancedSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 64px;
  margin-left: 24px;
`;

const AdvancedSectionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`;

const Header = styled(StyledHeader)`
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: normal;
  white-space: nowrap;
`;

const timeoutUnitOptions = [
  { key: "mins", value: "mins", text: "mins" },
  { key: "hrs", value: "hrs", text: "hrs" },
  { key: "days", value: "days", text: "days" },
];

type CustomTimeoutModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  customTimeoutUnit: string;
  setCustomTimeoutUnit: (unit: string) => void;
  handleCustomTimeout: (value: number, customTimeoutUnit: string) => void;
};

const CustomTimeoutModal = ({
  isOpen,
  setIsOpen,
  customTimeoutUnit,
  setCustomTimeoutUnit,
  handleCustomTimeout,
}: CustomTimeoutModalProps) => {
  const [timeout, setTimeout] = useState<number>(2);

  const handleCustomTimeoutChange = (value: number) => {
    setTimeout(parseInt(value as any));
  };

  const handleSubmit = () => {
    if (timeout <= 0) {
      beamtoast.error(
        `Custom Timeout value should be greater than 0 ${customTimeoutUnit}`
      );
      return;
    }
    setIsOpen(false);
    handleCustomTimeout(timeout, customTimeoutUnit);
  };

  return (
    <Modal
      closeIcon
      size="mini"
      className="dark"
      closeOnDimmerClick={false}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
    >
      <Modal.Header>Add Custom Timeout</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>Please set a custom timeout for Action</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <EditAnimatedMetaInput
              min="0"
              elementid="custom-timeout"
              placeholder="Enter Custom Timeout"
              type="number"
              label={`Custom Timeout (in ${customTimeoutUnit})`}
              value={timeout}
              onChangeEvent={(e) => handleCustomTimeoutChange(e.target.value)}
              style={{ width: "100%" }}
            />
            <Dropdown
              elementid="custom-timeout-unit"
              placeholder="Select Unit"
              selection
              options={timeoutUnitOptions}
              value={customTimeoutUnit}
              onChange={(e, { value }) => setCustomTimeoutUnit(value as string)}
              style={{
                minWidth: "95px",
                marginBottom: "10px",
                padding: "5px 10px",
              }}
            />
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button primary onClick={handleSubmit}>
          Add
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

type AdvanceSettingsSectionProps = {
  readonly endTimestamp: Date | number;
  readonly releaseNotes: string;
  readonly action: string;
  readonly setEndTimestamp: (endTimestamp: Date | number) => void;
  readonly setReleaseNotes: (releaseNotes: string) => void;
  readonly setRetryUntil: (retryUntil: Date | number) => void;
  readonly textEditorDimensions?: { height: string; width: string };
};

function AdvanceSettingsSection({
  endTimestamp,
  releaseNotes,
  action,
  setEndTimestamp,
  setReleaseNotes,
  setRetryUntil,
  textEditorDimensions = { height: "300px", width: "800px" },
}: AdvanceSettingsSectionProps) {
  const { user } = useUser();
  const theme = user?.settings?.theme ?? "dark";

  const [autoRetry, setAutoRetry] = useState<boolean>(false);
  const [isEnabledAdvancedSettings, setIsEnabledAdvancedSettings] =
    useState<boolean>(false);
  const [isCustomTimeoutOpen, setIsCustomTimeoutOpen] =
    useState<boolean>(false);
  const [customTimeoutUnit, setCustomTimeoutUnit] = useState<string>("days");
  const [timeoutDisplay, setTimeoutDisplay] = useState<string>("Never");

  const oneMinInMs = 60 * 1000; // milliseconds in a minute
  const oneHrInMs = 60 * oneMinInMs; // milliseconds in an hour
  const oneDayInMs = 24 * oneHrInMs; // milliseconds in a day

  const timeOutAfterOptions = useMemo(
    () => [
      {
        key: "Select Custom Time",
        text: "Select Custom Time",
        icon: "add",
        onClick: () => setIsCustomTimeoutOpen(true),
      },
      { key: "Never", text: "Never", value: neverTimestamp },
      { key: "2 mins", text: "2 mins", value: 2 * oneMinInMs },
      { key: "5 mins", text: "5 mins", value: 5 * oneMinInMs },
      { key: "10 mins", text: "10 mins", value: 10 * oneMinInMs },
      { key: "30 mins", text: "30 mins", value: 30 * oneMinInMs },
      { key: "1 hr", text: "1 hr", value: oneHrInMs },
      { key: "6 hrs", text: "6 hrs", value: 6 * oneHrInMs },
      { key: "12 hrs", text: "12 hrs", value: 12 * oneHrInMs },
      { key: "1 day", text: "1 day", value: oneDayInMs },
      { key: "3 days", text: "3 days", value: 3 * oneDayInMs },
      { key: "7 days", text: "7 days", value: 7 * oneDayInMs },
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleToggleChange = () => {
    const newAutoRetry = !autoRetry;
    setAutoRetry(newAutoRetry);
    setRetryUntil(newAutoRetry ? endTimestamp : 0);
  };

  const handleTimeout = (e, data) => {
    e.preventDefault();

    const value = data.value as Date | number;
    setEndTimestamp(value);
    setTimeoutDisplay(
      data.options.find((option) => option.value === value).text
    );
    if (autoRetry) {
      setRetryUntil(value);
    }
  };

  const handleCustomTimeout = (value: number, customTimeoutUnit: string) => {
    let timeoutUnitMultiplier: number;

    switch (customTimeoutUnit) {
      case "mins":
        timeoutUnitMultiplier = oneMinInMs;
        break;
      case "hrs":
        timeoutUnitMultiplier = oneHrInMs;
        break;
      case "days":
        timeoutUnitMultiplier = oneDayInMs;
        break;
      default:
        timeoutUnitMultiplier = oneDayInMs;
    }

    const timeoutValue = timeoutUnitMultiplier * value;

    const timeoutDisplayText =
      value === 1 && customTimeoutUnit.endsWith("s")
        ? `${value} ${customTimeoutUnit.slice(0, -1)}`
        : `${value} ${customTimeoutUnit}`;

    setTimeoutDisplay(timeoutDisplayText);
    setEndTimestamp(timeoutValue);
    setRetryUntil(autoRetry ? timeoutValue : 0);
  };

  return (
    <>
      <CustomTimeoutModal
        isOpen={isCustomTimeoutOpen}
        setIsOpen={setIsCustomTimeoutOpen}
        customTimeoutUnit={customTimeoutUnit}
        setCustomTimeoutUnit={setCustomTimeoutUnit}
        handleCustomTimeout={handleCustomTimeout}
      />

      <AdvancedSectionContainer style={{ marginLeft: "0px" }}>
        <AdvancedSectionItem>
          <Header as="h3">Advanced Settings</Header>
          <Toggle
            id="action-toggle"
            size="large"
            bgcolor="#05DB0A"
            checked={isEnabledAdvancedSettings}
            onChange={() =>
              setIsEnabledAdvancedSettings(!isEnabledAdvancedSettings)
            }
          />
        </AdvancedSectionItem>
      </AdvancedSectionContainer>
      {isEnabledAdvancedSettings && (
        <>
          <AdvancedSectionContainer
            style={{ marginTop: "36px", flexWrap: "wrap-reverse", gap: "24px" }}
          >
            <AdvancedSectionItem>
              <StyledNonBoldHeader as="h3" style={{ marginBottom: "0px" }}>
                Auto Retry
              </StyledNonBoldHeader>
              <Toggle
                id="action-toggle"
                size="small"
                bgcolor="#05DB0A"
                onChange={handleToggleChange}
                checked={autoRetry}
              />
            </AdvancedSectionItem>
            <AdvancedSectionItem>
              <StyledNonBoldHeader as="h3" style={{ marginBottom: "0px" }}>
                Mark Action as Timed Out after
              </StyledNonBoldHeader>
              <div style={{ marginTop: "12px" }}>
                <EditAnimatedMetaDropdown
                  elementid="time-out-after"
                  selection
                  options={timeOutAfterOptions}
                  value={endTimestamp as number}
                  onChange={handleTimeout}
                  text={timeoutDisplay}
                  labelBg={
                    ThemeSchema.data[theme]?.colors[
                      "action-card-container-background"
                    ]
                  }
                  style={{
                    maxWidth: "192px",
                    minWidth: "192px",
                    width: "192px",
                  }}
                />
              </div>
            </AdvancedSectionItem>
          </AdvancedSectionContainer>

          {action === "update_firmware" && (
            <AdvancedSectionContainer>
              <AdvancedSectionItem
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  marginTop: "18px",
                }}
              >
                <StyledNonBoldHeader as="h3" style={{ marginBottom: "0px" }}>
                  Release notes
                </StyledNonBoldHeader>
                <AceEditor
                  height={textEditorDimensions.height}
                  width={textEditorDimensions.width}
                  placeholder="Enter Release Notes"
                  mode="markdown"
                  theme={ThemeSchema.data[theme]?.colors["ace-editor-theme"]}
                  name="release-notes"
                  onChange={setReleaseNotes}
                  value={releaseNotes}
                  fontSize={14}
                  showPrintMargin={false}
                  showGutter={true}
                  highlightActiveLine={true}
                  setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                  }}
                  style={{
                    marginBottom: "16px",
                    borderRadius: "4px",
                    border: `${ThemeSchema.data[theme]?.colors["ace-editor-border"]}`,
                    boxShadow: `${ThemeSchema.data[theme]?.colors["ace-editor-box-shadow"]}`,
                  }}
                />
              </AdvancedSectionItem>
            </AdvancedSectionContainer>
          )}
        </>
      )}
    </>
  );
}

export default AdvanceSettingsSection;
