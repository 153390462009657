import { Alert, AlertsData, AlertsMetaData } from "./PanelDef";
import { PanelViewComponent } from "../PanelDef";
import React from "react";
import { capitalizeFirstLetter } from "../../../util";
import {
  Button,
  Label,
  MenuItem,
  Modal,
  Tab,
  TabPane,
  Table,
} from "semantic-ui-react";
import moment from "moment";
import { AlertRule } from "../../../../../util";
import styled from "styled-components";

const colorMap: { [key in AlertRule["criticality"]]?: string } = {
  critical: "#c53f4f",
  warning: "#9E9335",
  info: "#6D95D2",
};

const StyledTabs = styled(Tab)`
  &&& .ui.attached.tabular.menu {
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    border-bottom: none !important;
    position: sticky;
    z-index: 2;

    .item {
      border-bottom: 1px solid white !important;
    }
  }
`;

const StyledTabPane = styled(TabPane)`
  &&.ui.attached.segment {
    border: none !important;
  }
`;

function groupAlerts(list: Alert[]) {
  const map = new Map();

  list.forEach((item) => {
    const keyName = item.alert_rule.id;
    const collection = map.get(keyName);

    if (!collection) {
      map.set(keyName, [item]);
    } else {
      collection.push(item);
    }
  });

  return map;
}

function groupAlertRules(rules: AlertRule[]) {
  let map = rules.reduce((acc, rule) => {
    acc.set(rule.id, rule);
    return acc;
  }, new Map());

  return map;
}

type AlertListModalProps = {
  readonly alerts: Alert[];
  readonly alertRule: AlertRule;
  readonly trigger: React.ReactNode;
};

export default function AlertListModal(props: AlertListModalProps) {
  const [open, setOpen] = React.useState(false);

  return (
    <Modal
      className="dark"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={props.trigger}
    >
      <Modal.Header>
        {capitalizeFirstLetter(props.alertRule.name)} list
      </Modal.Header>
      <Modal.Description></Modal.Description>
      <Modal.Content>
        <Table>
          <Table.Header>
            <Table.HeaderCell>Device Id</Table.HeaderCell>
            <Table.HeaderCell>Start Time</Table.HeaderCell>
            <Table.HeaderCell>End Time</Table.HeaderCell>
            <Table.HeaderCell>Duration</Table.HeaderCell>
          </Table.Header>

          {props.alerts.map((alert, index) => {
            return (
              <Table.Row key={index}>
                <Table.Cell>{alert.device_id}</Table.Cell>
                <Table.Cell>
                  {new Date(alert.start_time).toLocaleString()}
                </Table.Cell>
                <Table.Cell>
                  {new Date(alert.end_time).toLocaleString()}
                </Table.Cell>
                <Table.Cell>
                  {moment
                    .duration(alert.end_time - alert.start_time)
                    .humanize({ ss: -1 })}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

type AlertCardProps = {
  readonly alerts: Alert[];
  readonly alertRule: AlertRule;
};

function AlertCard(props: AlertCardProps) {
  const colorMap = {
    critical: "#c53f4f",
    warning: "#9E9335",
    info: "#6D95D2",
  };

  const color = colorMap[props.alertRule.criticality];

  const containerStyle = {
    height: "80px",
    width: "calc(100% - 10px)",
    padding: "20px",
    color: "white",
    backgroundColor: color,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "20px",
    borderRadius: "10px",
    cursor: "pointer",
  } as React.CSSProperties;

  const numberStyle = {
    fontSize: "50px",
    fontWeight: "bold",
  } as React.CSSProperties;

  const nameStyle = {
    fontSize: "22px",
    marginLeft: "25px",
  } as React.CSSProperties;

  return (
    <div style={containerStyle} {...props}>
      <div style={numberStyle}>{props.alerts.length}</div>

      <div style={nameStyle}>{capitalizeFirstLetter(props.alertRule.name)}</div>
    </div>
  );
}

type ViewAlertsState = {
  activeIndex: number;
};

export class ViewAlerts extends PanelViewComponent<
  AlertsMetaData,
  AlertsData,
  ViewAlertsState
> {
  state = { activeIndex: 0 };

  handleTabChange = (e: React.MouseEvent, { activeIndex }: any) => {
    this.setState({ activeIndex });
  };

  render() {
    const tabbedView = this.props.panelMeta.tabbedView ?? false;
    const alerts = this.props.data.alerts;
    if (!alerts || alerts.length === 0) {
      return <div className="panel-no-data">No Data</div>;
    }
    const groupedAlerts = groupAlerts(alerts);
    const allAlertRules = this.props.data.alertRules;
    const groupedAlertRules = groupAlertRules(allAlertRules);

    const alertIds = Array.from(groupedAlerts.keys());
    const alertRules = alertIds
      .map((alertId) => groupedAlertRules.get(alertId))
      .sort((a, b) => {
        let levelMap = {
          critical: 3,
          warning: 2,
          info: 1,
        };

        return levelMap[b.criticality] - levelMap[a.criticality];
      });

    const hasMultipleAlertRules =
      Array.from(groupedAlertRules.values()).filter((alertRule) => {
        const alertsForRule = groupedAlerts.get(alertRule.id) || [];
        return alertsForRule.length > 0;
      }).length > 1;

    let tabs = [
      ...(hasMultipleAlertRules
        ? [
            {
              menuItem: (
                <MenuItem key="All Alerts">
                  All Alerts
                  <Label>{alerts.length}</Label>
                </MenuItem>
              ),
              render: () => (
                <StyledTabPane key="All Alerts">
                  <Table style={{ marginTop: "10px" }}>
                    <Table.Header>
                      <Table.HeaderCell>Device Id</Table.HeaderCell>
                      <Table.HeaderCell>Alert Name</Table.HeaderCell>
                      <Table.HeaderCell>Start Time</Table.HeaderCell>
                      <Table.HeaderCell>End Time</Table.HeaderCell>
                      <Table.HeaderCell>Duration</Table.HeaderCell>
                    </Table.Header>
                    {alerts.map((alert) => {
                      return (
                        <Table.Row key={alert.id}>
                          <Table.Cell>{alert.device_id}</Table.Cell>
                          <Table.Cell>{alert?.alert_rule?.name}</Table.Cell>
                          <Table.Cell>
                            {new Date(alert.start_time).toLocaleString()}
                          </Table.Cell>
                          <Table.Cell>
                            {new Date(alert.end_time).toLocaleString()}
                          </Table.Cell>
                          <Table.Cell>
                            {moment
                              .duration(alert.end_time - alert.start_time)
                              .humanize({ ss: -1 })}
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table>
                </StyledTabPane>
              ),
            },
          ]
        : []),

      // Add tabs for individual alert rules
      ...Array.from(groupedAlertRules.values())
        .filter((alertRule) => {
          // Removing alert tabs which has no data.
          const alertsForRule = groupedAlerts.get(alertRule.id) || [];
          return alertsForRule.length > 0;
        })
        .map((alertRule) => {
          const alertsForRule = groupedAlerts.get(alertRule.id) || [];
          return {
            menuItem: (
              <MenuItem key={alertRule.name}>
                {capitalizeFirstLetter(alertRule.name)}
                <Label
                  style={{ background: `${colorMap[alertRule.criticality]}` }}
                >
                  {alertsForRule.length}
                </Label>
              </MenuItem>
            ),
            render: () => (
              <StyledTabPane key={alertRule.name}>
                <Table style={{ marginTop: "10px" }}>
                  <Table.Header>
                    <Table.HeaderCell>Device Id</Table.HeaderCell>
                    <Table.HeaderCell>Start Time</Table.HeaderCell>
                    <Table.HeaderCell>End Time</Table.HeaderCell>
                    <Table.HeaderCell>Duration</Table.HeaderCell>
                  </Table.Header>

                  {alertsForRule.map((alert) => {
                    return (
                      <Table.Row key={alert.id}>
                        <Table.Cell>{alert.device_id}</Table.Cell>
                        <Table.Cell>
                          {new Date(alert.start_time).toLocaleString()}
                        </Table.Cell>
                        <Table.Cell>
                          {new Date(alert.end_time).toLocaleString()}
                        </Table.Cell>
                        <Table.Cell>
                          {moment
                            .duration(alert.end_time - alert.start_time)
                            .humanize({ ss: -1 })}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table>
              </StyledTabPane>
            ),
            alertRule: alertRule,
          };
        }),
    ];

    const containerStyle = {
      marginTop: "50px",
      marginLeft: "20px",
      marginRight: "20px",
      overflowY: "scroll",
      height: "calc(100% - 50px)",
    } as React.CSSProperties;

    if (!tabbedView) {
      return (
        <div style={containerStyle}>
          {alertRules.map((alertRule) => {
            const alerts = groupedAlerts.get(alertRule.id);

            if (alerts) {
              return (
                <AlertListModal
                  alertRule={alertRule}
                  alerts={alerts}
                  key={alertRule.alertName}
                  trigger={<AlertCard alerts={alerts} alertRule={alertRule} />}
                />
              );
            }

            return <div key={alertRule.alertName}></div>;
          })}
        </div>
      );
    } else {
      return (
        <div style={containerStyle}>
          <StyledTabs panes={tabs} />
        </div>
      );
    }
  }
}
