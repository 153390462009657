import React from "react";
import { capitalizeFirstLetter } from "../../util";
import { Dropdown, Icon, Progress, Table } from "semantic-ui-react";
import { LightMutedText } from "./Device";
import TextWithToolTip from "./TextWithToolTip";
import moment from "moment";
import { DeviceStyledTable } from "../../Actions/DeviceList";
import {
  cancelActions,
  completeAllActionsForDevices,
  getTenantFromURL,
  markActionAsCompleted,
  retryActions,
} from "../../../../BytebeamClient";
import ActionLogModal from "./ActionLogModal";
import { beamtoast } from "../../../common/CustomToast";
import { useUser } from "../../../../context/User.context";
import ConfirmationModal from "../../common/ConfirmationModal";
import ConfirmationModalMessageNonDelete from "../../common/ConfirmationModalMessageNonDelete";

export type LastActionType = {
  action_id: string;
  type: string;
  status: string;
  user_name: string;
  created_at: string;
  progress: number;
  errors: string;
};

type LastActionInfoTableProps = {
  lastAction: LastActionType;
  specificAction?: boolean;
  deviceId: number;
  serialMetadata?: string;
};

type handleCancelOrRetryActionType = {
  actionID: string;
  deviceID: number;
  operation: "cancel" | "retry";
};

export function styleActionProgress(action_status, action_progress) {
  let className: string = "in-progress",
    progress: number = action_progress;

  if (action_status === "Failed") {
    progress =
      action_progress !== 100 || action_progress !== 0 ? action_progress : 100;
    className = "failed";
  } else if (action_status === "Completed") {
    progress = 100;
    className = "completed";
  } else if (action_status === "Initiated") {
    className = "initiated";
    progress = 0;
  } else if (action_status === "Queued") {
    className = "queued";
    progress = 0;
  } else if (action_status === "Scheduled") {
    className = "scheduled";
    progress = 0;
  } else if (action_status === "PendingApproval") {
    className = "pending_approval";
    progress = 0;
  }

  return {
    progress,
    className,
  };
}

function LastActionInfoTable(props: LastActionInfoTableProps) {
  const { lastAction, deviceId, specificAction, serialMetadata } = props;

  const { user } = useUser();
  const serial_metadata = user?.["tenant-settings"]?.["serial-key"] ?? "";

  let lastActionId: string = String(lastAction?.action_id ?? "--"),
    lastActionType: string = capitalizeFirstLetter(lastAction?.type || "--"),
    lastActionStatus: string = lastAction?.status || "--",
    lastActionProgress: number = styleActionProgress(
      lastActionStatus,
      lastAction?.progress === undefined ? 0 : lastAction?.progress
    )["progress"],
    lastActionTriggeredBy: string = lastAction?.user_name ?? "--",
    lastActionTriggeredAt: string = lastAction?.created_at,
    lastActionErrors: string = "",
    lastActionClassName: string = styleActionProgress(
      lastActionStatus,
      lastAction?.progress === undefined ? 0 : lastAction?.progress
    )["className"];

  // When Actions is mark as completed lastAction.errors is "[]" else if no error it holds undefined or null value
  if (typeof lastAction?.errors === `string` && lastAction?.errors.length > 2) {
    // removing first two and last two characters from string since lastAction.errors is stringified array
    lastActionErrors = lastAction?.errors
      .substring(2, lastAction?.errors.length - 2)
      .trim();
  }

  const shouldShowActionLogs = true;

  const handleMarkLastAction = async (actionID: string, deviceID: number) => {
    try {
      if (actionID) {
        await markActionAsCompleted(actionID, {
          metadata: { id: [String(deviceID)] },
        });
        beamtoast.success(`Marked action ${actionID} as completed`);
      }
    } catch (e) {
      console.log(e);
      beamtoast.error(`Failed to mark action ${actionID} as completed`);
    }
  };

  const handleMarkAllDeviceActions = async (deviceID) => {
    try {
      if (deviceID) {
        let res = await completeAllActionsForDevices({
          id: [deviceID],
        });
        if (res && res?.count !== undefined) {
          beamtoast.success(`Marked all actions as complete`);
        }
      }
    } catch (e) {
      console.log(e);
      beamtoast.error("Failed to mark all actions as complete.");
    }
  };

  const handleCancelOrRetryAction = async ({
    actionID,
    deviceID,
    operation,
  }: handleCancelOrRetryActionType): Promise<void> => {
    try {
      let res;

      if (operation === "cancel") {
        res = await cancelActions(actionID, {
          device_ids: [deviceID],
        });
      } else if (operation === "retry") {
        res = await retryActions(actionID, {
          device_ids: [deviceID],
        });
      }

      if (res && res !== undefined) {
        beamtoast.success(
          `${operation === "cancel" ? "Cancelled" : "Retried"} action successfully`
        );
      }
    } catch (e) {
      console.log(e);
      beamtoast.error(`Failed to ${operation} action on devices`);
    }
  };

  return (
    <DeviceStyledTable compact fixed id={`${deviceId}-last_action`}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ padding: "9px 9.8px" }} colSpan={2}>
            {specificAction ? (
              `Action ID: ${lastActionId}`
            ) : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                Last Action
                <Dropdown
                  style={{ border: "none" }}
                  direction="left"
                  trigger={<Icon name="ellipsis vertical" />}
                  icon={null}
                >
                  <Dropdown.Menu>
                    {user.role.permissions.allowMarkActionAsCompleted && (
                      <ConfirmationModal
                        prefixContent="Mark All Actions as Complete"
                        expectedText={"Yes"}
                        onConfirm={() => {
                          handleMarkAllDeviceActions(deviceId);
                        }}
                        trigger={
                          <Dropdown.Item disabled={lastActionType === "--"}>
                            <Icon name="check square" />
                            Complete All Actions on device
                          </Dropdown.Item>
                        }
                        message={
                          <ConfirmationModalMessageNonDelete
                            name={"Mark All Actions as Complete"}
                            expectedText={"Yes"}
                            type={""}
                            specialMessage={`This will mark all Actions triggered on this Device(${serial_metadata ? `${serial_metadata}: ${serialMetadata}` : `id: ${deviceId}`}) which are not completed/failed as completed.`}
                          />
                        }
                      />
                    )}
                    {user.role.permissions.allowMarkActionAsCompleted && (
                      <ConfirmationModal
                        prefixContent={`Mark Last Action as Completed for Device(${serial_metadata ? `${serial_metadata}: ${serialMetadata}` : `id: ${deviceId}`})`}
                        expectedText={String(lastActionId)}
                        onConfirm={() => {
                          handleMarkLastAction(String(lastActionId), deviceId);
                        }}
                        trigger={
                          <Dropdown.Item
                            disabled={
                              lastActionStatus === "Completed" ||
                              lastActionStatus === "Failed" ||
                              lastActionType === "--" ||
                              lastAction.type === "cancel_action"
                            }
                          >
                            <Icon name="check circle outline" />
                            Complete Last Action
                          </Dropdown.Item>
                        }
                        message={
                          <ConfirmationModalMessageNonDelete
                            name={"Mark Action as Complete"}
                            expectedText={String(lastActionId)}
                            type={""}
                            specialMessage={`This will mark Action(id: ${lastActionId}, type: ${lastActionType}) on Device(${serial_metadata ? `${serial_metadata}: ${serialMetadata}` : `id: ${deviceId}`}) as completed.`}
                          />
                        }
                      />
                    )}

                    {user.role.permissions.allowedActions.includes(
                      `${lastAction.type}`
                    ) && (
                      <ConfirmationModal
                        prefixContent="Cancel Action"
                        expectedText={String(lastActionId)}
                        onConfirm={() => {
                          handleCancelOrRetryAction({
                            actionID: String(lastActionId),
                            deviceID: deviceId,
                            operation: "cancel",
                          });
                        }}
                        trigger={
                          <Dropdown.Item
                            disabled={
                              lastActionStatus === "Failed" ||
                              lastActionStatus === "Completed" ||
                              lastAction.type === "cancel_action" ||
                              lastAction.type === "launch_shell" ||
                              lastActionType === "--"
                            }
                          >
                            <Icon name="cancel" />
                            Cancel Last Action
                          </Dropdown.Item>
                        }
                        message={
                          <ConfirmationModalMessageNonDelete
                            name={"Cancel Action"}
                            expectedText={String(lastActionId)}
                            type={""}
                            specialMessage={`This will cancel the Action (id: ${lastActionId}, type: ${lastActionType}).`}
                          />
                        }
                      />
                    )}

                    {
                      // Check if the user has permission for the action.
                      user.role.permissions.allowedActions.includes(
                        `${lastAction.type}`
                      ) && (
                        <ConfirmationModal
                          prefixContent="Retry Action"
                          expectedText={String(lastActionId)}
                          onConfirm={() => {
                            handleCancelOrRetryAction({
                              actionID: String(lastActionId),
                              deviceID: deviceId,
                              operation: "retry",
                            });
                          }}
                          trigger={
                            <Dropdown.Item
                              disabled={
                                lastActionStatus !== "Failed" ||
                                lastAction.type === "cancel_action" ||
                                lastAction.type === "launch_shell"
                              }
                            >
                              <Icon name="redo" />
                              Retry Last Action
                            </Dropdown.Item>
                          }
                          message={
                            <ConfirmationModalMessageNonDelete
                              name={"Retry Action"}
                              expectedText={String(lastActionId)}
                              type={""}
                              specialMessage={`This will retry the Action (id: ${lastActionId}, type: ${lastActionType}).`}
                            />
                          }
                        />
                      )
                    }
                    {lastAction && shouldShowActionLogs ? (
                      <ActionLogModal
                        actionId={lastAction.action_id}
                        trigger={
                          <Dropdown.Item disabled={lastActionType === "--"}>
                            <Icon name="file alternate" />
                            View logs
                          </Dropdown.Item>
                        }
                        deviceId={props.deviceId.toString()}
                        actionStatus={lastAction.status}
                      />
                    ) : null}
                    <Dropdown.Item
                      onClick={() =>
                        window.open(
                          `/projects/${getTenantFromURL()}/actions/live-actions?action_id=${
                            lastAction?.action_id
                          }`,
                          "_blank"
                        )
                      }
                      disabled={lastActionType === "--"}
                    >
                      <Icon name="eye" />
                      View details
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      {lastActionType !== "--" ? (
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan={2}>
              <div style={{ margin: "1rem 0" }}>
                <Progress
                  progress
                  indicating={
                    lastActionStatus === "Completed" ||
                    lastActionStatus === "Failed"
                      ? false
                      : true
                  }
                  size="medium"
                  percent={lastActionProgress}
                  className={lastActionClassName}
                  style={{ width: "100%", marginBottom: "0px" }}
                />
              </div>
            </Table.Cell>
          </Table.Row>
          {lastActionErrors !== "" && (
            <Table.Row>
              <Table.Cell>
                <LightMutedText>Errors</LightMutedText>
              </Table.Cell>
              <Table.Cell>
                <TextWithToolTip text={lastActionErrors} />
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>
              <LightMutedText>Action ID</LightMutedText>
            </Table.Cell>
            <Table.Cell>
              <TextWithToolTip text={lastActionId} />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <LightMutedText>Action Type</LightMutedText>
            </Table.Cell>
            <Table.Cell>
              <TextWithToolTip text={lastActionType} />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <LightMutedText>Action Status</LightMutedText>
            </Table.Cell>
            <Table.Cell>
              <TextWithToolTip text={capitalizeFirstLetter(lastActionStatus)} />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <LightMutedText>Triggered By</LightMutedText>
            </Table.Cell>
            <Table.Cell>
              <TextWithToolTip text={lastActionTriggeredBy} />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <LightMutedText>Triggered At</LightMutedText>
            </Table.Cell>
            <Table.Cell>
              <TextWithToolTip
                text={capitalizeFirstLetter(
                  moment
                    .duration(-1 * moment().diff(moment(lastActionTriggeredAt)))
                    .humanize(true)
                )}
                textList={[
                  `${new Date(lastActionTriggeredAt).toLocaleString("en-GB")}`,
                ]}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      ) : (
        <Table.Row>
          <Table.Cell>No Last Action</Table.Cell>
        </Table.Row>
      )}
    </DeviceStyledTable>
  );
}

export default LastActionInfoTable;
