import React, { ReactNode, useEffect } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  TrafficLayer,
  Polygon,
} from "react-google-maps";

import { GOOGLE_MAPS_API_KEY } from "../../../../../constants/index";
import { LocateDevicesMetaData, LocateDevicesData } from "./PanelDef";
import { PanelViewComponent } from "../PanelDef";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import { DeviceCoordinates } from "../TrackDevices/PanelDef";
import { ReplayState } from "../../DashboardHeader";

import { darkTheme, lightTheme } from "../TrackDevices/MapStyle";
import { AlertRuleCriticality, Settings } from "../../../../../util";
import {
  DeviceConfigurationType,
  getTenantFromURL,
} from "../../../../../BytebeamClient";
import { DashboardsInfo } from "../../ViewDashboard";
import { Icon } from "semantic-ui-react";
import { capitalizeFirstLetter } from "../../../util";
import { StateColor } from "../StateTimeline/PanelDef";
import { Alert } from "../Alerts/PanelDef";
import { useUser } from "../../../../../context/User.context";

export const mapColors = ["#FF0500", "#00e653", "#238cfe", "#febf14"];

function getMaxAlertLevel(alerts: Alert[]): AlertRuleCriticality | "none" {
  let maxLevel: AlertRuleCriticality | "none" = "none";

  alerts.forEach((alert) => {
    const alertRule = alert.alert_rule;

    if (alertRule.criticality === "critical") {
      return "critical"; // since critical is the highest, we can return immediately
    } else if (alertRule.criticality === "warning" && maxLevel !== "critical") {
      maxLevel = "warning";
    }
  });

  return maxLevel;
}

type MarkerWithInfoWindowProps = {
  deviceId: string;
  coordinates: DeviceCoordinates;
  deviceDashboardIds: string[];
  color: string;
  metadata: { [key: string]: string };
  state: { [key: string]: string };
  settings: Settings;
  allDashboards: DashboardsInfo[];
  markerIcon: string;
  metadataKeys?: string[];
  deviceShadowKeys?: string[];
  geofenceConfig?: DeviceConfigurationType;
  alerts: Alert[];
  headerContent?: string;
};

export class MarkerWithInfoWindow extends React.Component<MarkerWithInfoWindowProps> {
  state = {
    infoWindowOpen: false,
  };

  render() {
    const {
      deviceId,
      coordinates,
      metadata,
      state,
      alerts,
      deviceDashboardIds,
      headerContent,
    } = this.props;

    const infoWindowContentPresent =
      (this.props.metadataKeys && this.props.metadataKeys.length > 0) ||
      (this.props.deviceShadowKeys && this.props.deviceShadowKeys.length > 0) ||
      (deviceDashboardIds.length > 0 && deviceDashboardIds[0].length > 0) ||
      headerContent;

    const showInfoWindow = this.state.infoWindowOpen;
    const serial_number = this.props.settings["serial_number"];
    const currentTenant = getTenantFromURL();

    let paths = [];

    if (this.props.geofenceConfig) {
      paths = this.props.geofenceConfig["config_json"]["path"].map((point) => {
        return new google.maps.LatLng(point["lat"], point["lng"]);
      });
    }

    const markerIcons = {
      default: "\uf3c5",
      motorcycle: "\uf21c",
      car: "\uf1b9",
      truck: "\uf0d1",
      arrow: "\uf124",
    };

    const evageIconMap = {
      running: "\uf124",
      charging: "\uf0e7",
      idle: "\uf186",
      off: "\uf04d",
    };

    const alertCriticality = getMaxAlertLevel(alerts);
    const alertColorMap = {
      none: "lime",
      info: "#6D95D2",
      warning: "yellow",
      critical: "red",
    };

    let iconText = markerIcons[this.props.markerIcon];
    let iconColor = this.props.color;
    let icon: string | google.maps.Symbol = " ";

    if (getTenantFromURL() === "evage" || getTenantFromURL() === "evagedemo") {
      let status = state["Status"];
      iconText = evageIconMap[state["Status"]];

      if (status === "offline") {
        icon = {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 18,
          strokeWeight: 0,
          fillColor: "grey",
          fillOpacity: 1,
        };
      } else {
        const fillColor = alertColorMap[alertCriticality];
        icon = {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 18,
          strokeWeight: 0,
          fillColor: fillColor,
          fillOpacity: 1,
        };
      }
      iconColor = "black";
    }

    if (headerContent === "Start Location") {
      icon = {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 6,
        strokeWeight: 3,
        strokeColor: 'black',
        fillColor: 'white',
        fillOpacity: 1,
      };
      iconText = undefined;
      iconColor = 'black';
    }

    return (
      // @ts-ignore
      <Marker
        position={
          new google.maps.LatLng(coordinates.latitude, coordinates.longitude)
        }
        label={{
          text: iconText,
          fontFamily: "Icons",

          // Pick a random color from the array
          color: iconColor,
          fontSize: "20px",
        }}
        icon={icon}
        key={deviceId}
        onClick={() =>
          this.setState({ infoWindowOpen: !this.state.infoWindowOpen })
        }
      >
        {showInfoWindow && infoWindowContentPresent && (
          // @ts-ignore
          <InfoWindow
            onCloseClick={() => this.setState({ infoWindowOpen: false })}
          >
            <div>
              {headerContent && (
                <div
                  style={{
                    fontWeight: "bold",
                    marginBottom: "8px",
                    color: "#000",
                  }}
                >
                  {headerContent}
                </div>
              )}
              {metadata && (
                <div style={{ color: "black" }}>
                  {(this.props.metadataKeys || []).map((key) => {
                    const value = metadata[key] || "--";
                    return (
                      <div key={key}>{`${capitalizeFirstLetter(key)} : ${
                        value as ReactNode
                      }`}</div>
                    );
                  })}
                </div>
              )}

              {state && (
                <div style={{ color: "black", marginBottom: "10px" }}>
                  {(this.props.deviceShadowKeys || []).map((key) => {
                    const value = state[key] || "--";
                    return (
                      <div key={key}>{`${capitalizeFirstLetter(key)} : ${
                        value as ReactNode
                      }`}</div>
                    );
                  })}
                </div>
              )}

              {deviceDashboardIds.map((id) => {
                let url = `/projects/${currentTenant}/dashboards/${id}?id=${deviceId}`;
                let dashboard = this.props.allDashboards.find(
                  (dashboard) => String(dashboard.id) === id
                );

                if (
                  this.props.metadata &&
                  this.props.settings &&
                  serial_number &&
                  this.props.metadata[serial_number]
                ) {
                  url = `/projects/${currentTenant}/dashboards/${id}?${serial_number}=${this.props.metadata[serial_number]}`;
                }

                if (dashboard) {
                  return (
                    <div key={id}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={url}
                        id={`${id}_dashboardLink`}
                      >
                        <Icon name="external" />{" "}
                        {dashboard?.title || "View Device dashboard"}
                      </a>
                    </div>
                  );
                } else {
                  return <></>;
                }
              })}
            </div>
          </InfoWindow>
        )}

        {this.props.geofenceConfig ? (
          <Polygon
            paths={paths}
            options={{
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#FF0000",
              fillOpacity: 0.35,
            }}
          />
        ) : (
          <></>
        )}
      </Marker>
    );
  }
}

type MapComponentProps = {
  devices: LocateDevicesData;
  deviceDashboardIds: string[];
  trafficLayerFlag: boolean;
  satelliteLayerFlag: boolean;
  replayTimestamp: number;
  replayStep: number;
  replayState: ReplayState;
  settings: Settings;
  children?: React.ReactNode;
  allDashboards: DashboardsInfo[];
  markerIcon: string;
  markerColor: string;
  colorMapping: StateColor[];
  metadataKeys: string[];
  deviceShadowKeys: string[];
};

const MapComponent = compose<MapComponentProps, MapComponentProps>(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&key=" +
      GOOGLE_MAPS_API_KEY +
      "&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: "100%" }} />,
    mapElement: <div style={{ height: `100%`, borderRadius: "8px" }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const mapsRef = React.createRef<GoogleMap>();

  const { user } = useUser();
  const theme = user?.settings?.theme ?? "dark";
  const mapTheme = theme === "light" ? lightTheme : darkTheme;

  useEffect(() => {
    const bounds = new google.maps.LatLngBounds();
    if (props.devices && props.devices.length > 0) {
      let minLat = Infinity;
      let maxLat = -Infinity;
      let minLng = Infinity;
      let maxLng = -Infinity;

      props.devices.forEach((device) => {
        let coords = device.coordinates[0];
        minLat = Math.min(minLat, coords.latitude);
        maxLat = Math.max(maxLat, coords.latitude);
        minLng = Math.min(minLng, coords.longitude);
        maxLng = Math.max(maxLng, coords.longitude);
      });

      let offset = 0.008; // Adjust this value as needed. Larger values will zoom out more.
      if (props.devices.length > 1) {
        offset = 0;
      }

      bounds.extend(new google.maps.LatLng(minLat - offset, minLng - offset));
      bounds.extend(new google.maps.LatLng(maxLat + offset, maxLng + offset));

      mapsRef.current?.fitBounds(bounds);
    }
  });

  let defaultOptions = {
    streetViewControl: false,
    scaleControl: false,
    mapTypeControl: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    mapTypeControlOptions: {},
    panControl: true,
    zoomControl: false,
    rotateControl: true,
    fullscreenControl: false,
    styles: mapTheme,
  };

  if (props.satelliteLayerFlag) {
    // showing satellite layer
    defaultOptions = {
      streetViewControl: false,
      scaleControl: false,
      mapTypeControl: true,
      mapTypeId: google.maps.MapTypeId.SATELLITE,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [
          google.maps.MapTypeId.ROADMAP,
          google.maps.MapTypeId.SATELLITE,
          google.maps.MapTypeId.HYBRID,
        ],
      },
      panControl: true,
      zoomControl: false,
      rotateControl: true,
      fullscreenControl: false,
      styles: mapTheme,
    };
  }

  return (
    // @ts-ignore
    <GoogleMap
      ref={mapsRef}
      zoom={1}
      // center={{ lat: 12.927381, lng: 77.637729 }}   Commented to fix the map glitching issue as it was recentering on every re-render
      options={defaultOptions}
    >
      {props.trafficLayerFlag && <TrafficLayer />}
      {/* @ts-ignore */}
      <MarkerClusterer minimumClusterSize={3}>
        {props.devices.map((device) => {
          const status = device.state?.["Status"] || "OnTrip";
          const colorMapping = props.colorMapping.find(
            (mapping) => mapping.state === status
          ) || { color: props.markerColor || "#7db2ef" };

          const color = colorMapping.color;
          const coordinates = device.coordinates;

          let coordIndex = coordinates.length - 1;

          if (
            props.replayState === ReplayState.ReplayRunning ||
            props.replayState === ReplayState.ReplayPaused
          ) {
            coordIndex = props.replayStep;

            if (coordIndex > coordinates.length - 1) {
              coordIndex = coordinates.length - 1;
            }
          }

          const currentLocation = coordinates[coordIndex];

          return (
            <MarkerWithInfoWindow
              color={color}
              key={device.id}
              deviceId={device.id}
              coordinates={currentLocation}
              metadata={device.metadata}
              state={device.state}
              alerts={device.alerts || []}
              settings={props.settings}
              deviceDashboardIds={props.deviceDashboardIds}
              allDashboards={props.allDashboards}
              markerIcon={props.markerIcon}
              metadataKeys={props.metadataKeys}
              deviceShadowKeys={props.deviceShadowKeys}
            />
          );
        })}
      </MarkerClusterer>
    </GoogleMap>
  );
});

export class ViewLocateDevices extends PanelViewComponent<
  LocateDevicesMetaData,
  LocateDevicesData
> {
  render() {
    let deviceDashboardIds = this.props.panelMeta.device_dashboard_ids || [];

    if (
      deviceDashboardIds.length === 0 &&
      this.props.panelMeta.device_dashboard_id
    ) {
      deviceDashboardIds = [this.props.panelMeta.device_dashboard_id];
    }

    return (
      <MapComponent
        devices={this.props.data}
        deviceDashboardIds={deviceDashboardIds}
        trafficLayerFlag={this.props.panelMeta.trafficLayerFlag}
        satelliteLayerFlag={this.props.panelMeta.satelliteLayerFlag}
        replayTimestamp={this.props.replayStep}
        replayState={this.props.replayState}
        replayStep={this.props.replayStep}
        settings={this.props.settings}
        markerIcon={this.props.panelMeta.markerIcon || "default"}
        markerColor={this.props.panelMeta.markerColor || "#7db2ef"}
        allDashboards={this.props.dashboards}
        colorMapping={this.props.panelMeta.colorMapping || []}
        metadataKeys={this.props.panelMeta.metadataKeys || []}
        deviceShadowKeys={this.props.panelMeta.deviceShadowKeys || []}
      />
    );
  }
}
