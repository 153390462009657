import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  CreateActionContainer,
  NewActionLabelContainer,
  NewActionWrapper,
  StyledNonBoldHeader,
} from "./NewAction";
import CreateOrEditFirmwareModal from "../Inventory/FirmwareVersions/CreateOrEditFirmwareModal";
import CreateJSONConfigModal from "./ActionTypesModal/CreateJSONConfigModal";
import { StyledHeader } from "../ActionsV3/SelectableItem";
import { Dropdown } from "semantic-ui-react";
import { EditAnimatedMetaDropdown } from "../../Dashboards/Panel/util";
import LoadingAnimation from "../../../common/Loader";
import { OptionType } from "../util";
import { DropdownOptionType } from "../../DeviceManagement/Devices/ActionModals/UpdateConfigModal";
import {
  ActionDropdownType,
  FirmwareType,
  fetchAllActionTypes,
  fetchAllDeviceConfiguration,
  fetchAllFirmwares,
} from "../../../../BytebeamClient";
import { capitalizeFirstLetter } from "../../util";
import { useUser } from "../../../../context/User.context";
import CreateGeofenceConfigModal from "./ActionTypesModal/CreateGeofenceConfigModal";
import CustomActionModal from "./ActionTypesModal/CustomActionModal";
import { StyledFileUploadButton } from "../../DeviceManagement/Devices/ActionModals/SendFileModal";
import SendFileActionModal from "./ActionTypesModal/SendFileActionModal";
import { CardContainer } from "../../../common/ActionsUtils";
import ThemeSchema from "../../../../theme/schema";

const NewActionFieldContainer = styled.div`
  display: flex;
  align-items: center;
  width: 450px;
  margin-bottom: 16px;
`;

const ActionSelectOrUploadContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors["action-border-color"]};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
`;

type CreateActionSectionProps = {
  readonly action: string;
  readonly setAction: (action: string) => void;
  readonly optionType: OptionType;
  readonly setOptionType: (optionType: OptionType) => void;
  readonly selectedVersion: string;
  readonly setSelectedVersion: (version: string) => void;
  readonly payload: Record<string, any> | string;
  readonly setPayload: (payload: Record<string, any> | string) => void;
};

function CreateActionSection(props: CreateActionSectionProps) {
  const {
    action,
    setAction,
    optionType,
    setOptionType,
    selectedVersion,
    setSelectedVersion,
    payload,
    setPayload,
  } = props;

  const { user } = useUser();
  const theme = user.settings?.theme ?? "dark";
  const allowedActions = user.role.permissions.allowedActions;

  const [actionDropdownList, setActionDropdownList] = useState<
    ActionDropdownType[]
  >([]);
  const [selectedAction, setSelectedAction] = useState<ActionDropdownType>(
    {} as ActionDropdownType
  );
  const [versionSelectionLoader, setVersionSelectionLoader] =
    useState<boolean>(false);

  // Update Firmware Action State
  const [allExistingFirmwareVersions, setAllExistingFirmwareVersions] =
    useState<FirmwareType[]>([] as FirmwareType[]);
  const [firmwareOptions, setFirmwareOptions] = useState<DropdownOptionType[]>(
    []
  );
  const [showUploadFirmwareModal, setShowUploadFirmwareModal] =
    useState<boolean>(false);
  const [uploadedFileName, setUploadedFileName] = useState<string>("");

  // Update JSON Config Action State
  const [configJSONOptions, setConfigJSONOptions] = useState<
    DropdownOptionType[]
  >([]);
  const [showCreateJSONConfigModal, setShowCreateJSONConfigModal] =
    useState<boolean>(false);

  // Update GeoFence Config Action State
  const [configGeofenceOptions, setConfigGeofenceOptions] = useState<
    DropdownOptionType[]
  >([]);
  const [showCreateGeofenceConfigModal, setShowCreateGeofenceConfigModal] =
    useState<boolean>(false);

  // Custom Action Payload Modal State
  const [showCustomActionModal, setShowCustomActionModal] =
    useState<boolean>(false);

  // Send File Action Modal State
  const [showSendFileActionModal, setShowSendFileActionModal] =
    useState<boolean>(false);

  const fillFirmwareDropdownOptions = async () => {
    setVersionSelectionLoader(true);
    try {
      let res = await fetchAllFirmwares();
      setAllExistingFirmwareVersions(res);

      const options = res.flatMap((o) =>
        o.is_deactivated
          ? []
          : [
              {
                key: o.version_number,
                value: o.version_number,
                text: o.version_number,
              },
            ]
      );
      setFirmwareOptions(options);
    } catch (e) {
      console.log(e);
    } finally {
      setVersionSelectionLoader(false);
    }
  };

  const fillConfigDropdownOptions = async () => {
    setVersionSelectionLoader(true);
    try {
      const res = await fetchAllDeviceConfiguration();

      // Setting Device Config Options
      const JSONOptions = res.flatMap((config) =>
        (config.action_type === "update_config" ||
          config.action_type === null) &&
        !config.is_deactivated
          ? [
              {
                key: config.version_name,
                value: config.version_name,
                text: config.version_name,
              },
            ]
          : []
      );
      setConfigJSONOptions(JSONOptions);

      // Setting Geofence Config Options
      const GeofenceOptions = res.flatMap((config) =>
        config.action_type === "update_geofence" && !config.is_deactivated
          ? [
              {
                key: config.version_name,
                value: config.version_name,
                text: config.version_name,
              },
            ]
          : []
      );
      setConfigGeofenceOptions(GeofenceOptions);
    } catch (e) {
      console.log(e);
    } finally {
      setVersionSelectionLoader(false);
    }
  };

  const handlePayloadType = (payloadType, actionType) => {
    if (
      payloadType === "none" &&
      !(actionType === "send_file" || actionType === "send_script")
    ) {
      setOptionType(OptionType.NoPayloadOption);
    } else if (
      payloadType === "json" &&
      actionType !== "update_config" &&
      actionType !== "update_geofence"
    ) {
      setOptionType(OptionType.UploadCommonConfig);
    } else if (payloadType === "text") {
      setOptionType(OptionType.UploadText);
    } else {
      setOptionType(OptionType.NoOptionSelected);
    }
  };

  const handleActionType = (selectedAction) => {
    const { value: actionType, payload_type: payloadType } = selectedAction;

    switch (actionType) {
      case "update_firmware":
        setOptionType(OptionType.ChooseFirmware);
        fillFirmwareDropdownOptions();
        break;
      case "update_config":
        setOptionType(OptionType.ChooseConfig);
        fillConfigDropdownOptions();
        break;
      case "update_geofence":
        setOptionType(OptionType.ChooseGeofence);
        fillConfigDropdownOptions();
        break;
      case "send_file":
        setOptionType(OptionType.SendFile);
        break;
      case "send_script":
        setOptionType(OptionType.SendScript);
        break;
      default:
        handlePayloadType(payloadType, actionType);
        break;
    }
  };

  const setActionType = (_event, data) => {
    const selectedActionDropdownList = actionDropdownList.find(
      (actionType) => actionType.value === data.value
    );

    if (!selectedActionDropdownList) return;

    setSelectedAction(selectedActionDropdownList);
    setAction(selectedActionDropdownList.value);

    handleActionType(selectedActionDropdownList);

    setUploadedFileName("");
    setSelectedVersion("");
    setPayload("");
  };

  const versionOptions = useMemo(() => {
    if (action === "update_firmware") {
      return firmwareOptions;
    } else if (action === "update_config") {
      return configJSONOptions;
    } else if (action === "update_geofence") {
      return configGeofenceOptions;
    } else {
      return [
        {
          text: "No action selected",
          value: "No action selected",
        },
      ] as DropdownOptionType[];
    }
  }, [action, firmwareOptions, configJSONOptions, configGeofenceOptions]);

  const handleVersionSelect = (e, data) => {
    setSelectedVersion(data.value);
    if (action === "update_firmware") {
      setOptionType(OptionType.ChooseFirmware);
    } else if (action === "update_config") {
      setOptionType(OptionType.ChooseConfig);
    } else if (action === "update_geofence") {
      setOptionType(OptionType.ChooseGeofence);
    }
  };

  const handleModalOpen = () => {
    if (action === "update_firmware") {
      setShowUploadFirmwareModal(true);
    } else if (action === "update_config") {
      setShowCreateJSONConfigModal(true);
    } else if (action === "update_geofence") {
      setShowCreateGeofenceConfigModal(true);
    } else if (action === "send_file" || action === "send_script") {
      setShowSendFileActionModal(true);
    } else {
      setShowCustomActionModal(true);
    }
  };

  useEffect(() => {
    const getActionTypes = async () => {
      try {
        const res = await fetchAllActionTypes();
        const actionTypesToRender = res.filter(
          (actionType) =>
            actionType.type !== "launch_shell" &&
            actionType.type !== "cancel_action" &&
            actionType.type !== "retry_action" &&
            allowedActions.includes(actionType.type)
        );

        const dropdownItems = actionTypesToRender.map((actionType) => ({
          text: capitalizeFirstLetter(actionType.type),
          value: actionType.type,
          icon: actionType.icon,
          payload_type: actionType.payload_type,
          json_schema: actionType.json_schema ?? "",
          json_ui_schema: actionType.json_ui_schema ?? "",
        }));

        setActionDropdownList(dropdownItems);
      } catch (error) {
        console.error("Error fetching action types:", error);
      }
    };

    getActionTypes();
  }, [allowedActions]);

  return (
    <CreateActionContainer>
      <CreateOrEditFirmwareModal
        type="Add"
        isOpen={showUploadFirmwareModal}
        close={() => setShowUploadFirmwareModal(false)}
        allExistingFirmwareVersions={allExistingFirmwareVersions}
        setOptionType={(value) => setOptionType(value)}
        setSelectedVersion={(value) => setSelectedVersion(value)}
        setUploadedFileName={(value) => setUploadedFileName(value)}
      />
      <CreateJSONConfigModal
        isOpen={showCreateJSONConfigModal}
        onClose={() => setShowCreateJSONConfigModal(false)}
        setOptionType={(value) => setOptionType(value)}
        setSelectedVersion={(value) => setSelectedVersion(value)}
        allConfigOptions={
          new Set(configJSONOptions.map((o) => o.value?.toLowerCase()))
        }
      />
      <CreateGeofenceConfigModal
        isOpen={showCreateGeofenceConfigModal}
        onClose={() => setShowCreateGeofenceConfigModal(false)}
        setOptionType={(value) => setOptionType(value)}
        setSelectedVersion={(value) => setSelectedVersion(value)}
        allConfigOptions={
          new Set(configGeofenceOptions.map((o) => o.value?.toLowerCase()))
        }
      />
      <SendFileActionModal
        isOpen={showSendFileActionModal}
        onClose={() => setShowSendFileActionModal(false)}
        optionType={optionType}
        setSelectedVersion={(value) => setSelectedVersion(value)}
        setUploadedFileName={(value) => setUploadedFileName(value)}
      />
      <CustomActionModal
        isOpen={showCustomActionModal}
        onClose={() => setShowCustomActionModal(false)}
        selectedAction={selectedAction}
        payload={payload}
        setPayload={setPayload}
      />
      <CardContainer>
        <NewActionWrapper>
          <StyledHeader
            as="h2"
            style={{ marginTop: "0px", marginBottom: "18px" }}
          >
            Create a New Action
          </StyledHeader>

          <NewActionFieldContainer
            style={{ flexDirection: "column", alignItems: "flex-start" }}
          >
            <StyledHeader
              as="h3"
              style={{
                marginTop: "0px",
                marginBottom: "15px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Select Action
              {actionDropdownList.length === 0 && (
                <span style={{ marginLeft: "10px" }}>
                  <LoadingAnimation loaderSize="20px" />
                </span>
              )}
            </StyledHeader>

            <EditAnimatedMetaDropdown
              placeholder="Action"
              search
              selection
              // defaultValue={returnActionValue()}
              options={actionDropdownList}
              onChange={setActionType}
              elementid={`newActionDropdown1`}
              disabled={actionDropdownList.length === 0}
              labelBg={
                ThemeSchema.data[theme]?.colors[
                  "action-card-container-background"
                ]
              }
            />
          </NewActionFieldContainer>

          {action !== "" && (
            <NewActionLabelContainer>
              {["update_firmware", "update_config", "update_geofence"].includes(
                selectedAction.value
              ) && (
                <>
                  <ActionSelectOrUploadContainer>
                    <StyledNonBoldHeader
                      as="h4"
                      style={{
                        display: "flex",
                        marginBottom: "10px",
                        alignItems: "center",
                      }}
                    >
                      Select{" "}
                      {(() => {
                        if (selectedAction.value === "update_firmware") {
                          return "Firmware";
                        } else if (selectedAction.value === "update_config") {
                          return "Config";
                        } else if (selectedAction.value === "update_geofence") {
                          return "Geofence";
                        } else {
                          return "Version";
                        }
                      })()}
                      {versionSelectionLoader && (
                        <span style={{ marginLeft: "10px" }}>
                          <LoadingAnimation loaderSize="20px" />
                        </span>
                      )}
                    </StyledNonBoldHeader>

                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        width: "230px",
                      }}
                    >
                      <Dropdown
                        placeholder="Select Version"
                        fluid
                        selection
                        search
                        options={versionOptions}
                        onChange={handleVersionSelect}
                        disabled={versionSelectionLoader}
                      />
                    </div>
                  </ActionSelectOrUploadContainer>

                  <StyledNonBoldHeader
                    as="h3"
                    style={{
                      marginRight: "20px",
                      marginLeft: "20px",
                    }}
                  >
                    OR
                  </StyledNonBoldHeader>
                </>
              )}

              {(["send_file", "send_script"].includes(selectedAction.value) ||
                selectedAction.payload_type !== "none") && (
                <ActionSelectOrUploadContainer>
                  <StyledNonBoldHeader
                    as="h4"
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    Upload{" "}
                    {(() => {
                      if (selectedAction.value === "update_firmware") {
                        return "Firmware File";
                      } else if (selectedAction.value === "update_config") {
                        return "JSON Config";
                      } else if (selectedAction.value === "update_geofence") {
                        return "Geofence Config";
                      } else if (selectedAction.value === "send_file") {
                        return "File";
                      } else if (selectedAction.value === "send_script") {
                        return "Script";
                      } else if (selectedAction.payload_type === "json") {
                        return "JSON Payload";
                      } else if (selectedAction.payload_type === "text") {
                        return "Text Payload";
                      } else {
                        return "";
                      }
                    })()}
                    {versionSelectionLoader && (
                      <span style={{ marginLeft: "10px" }}>
                        <LoadingAnimation loaderSize="20px" />
                      </span>
                    )}
                  </StyledNonBoldHeader>

                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      width: "230px",
                    }}
                  >
                    <StyledFileUploadButton
                      fluid
                      content={`Click here to ${
                        (optionType === OptionType.UploadCommonConfig ||
                          optionType === OptionType.UploadText) &&
                        payload !== ""
                          ? "View/Edit"
                          : "Upload"
                      }`}
                      labelPosition="left"
                      icon="upload"
                      disabled={versionSelectionLoader}
                      onClick={handleModalOpen}
                    />
                  </div>
                </ActionSelectOrUploadContainer>
              )}

              {(selectedVersion !== "" || uploadedFileName !== "") && (
                <ActionSelectOrUploadContainer
                  style={{
                    marginLeft: "24px",
                    gap: "16px",
                    alignSelf: "stretch",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {selectedVersion !== "" &&
                    optionType !== OptionType.SendFile &&
                    optionType !== OptionType.SendScript && (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          justifyContent: "space-between",
                          gap: "16px",
                        }}
                      >
                        <StyledNonBoldHeader
                          as="h4"
                          style={{
                            marginBottom: "0px",
                            fontSize: "14px",
                          }}
                        >
                          Selected Version:
                        </StyledNonBoldHeader>
                        <StyledNonBoldHeader
                          as="h4"
                          style={{
                            marginBottom: "0px",
                            fontSize: "14px",
                          }}
                        >
                          {selectedVersion}
                        </StyledNonBoldHeader>
                      </div>
                    )}
                  {uploadedFileName !== "" && (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        justifyContent: "space-between",
                        gap: "16px",
                      }}
                    >
                      <StyledNonBoldHeader
                        as="h4"
                        style={{
                          marginBottom: "0px",
                          fontSize: "14px",
                        }}
                      >
                        Uploaded File:
                      </StyledNonBoldHeader>
                      <StyledNonBoldHeader
                        as="h4"
                        style={{
                          marginBottom: "0px",
                          fontSize: "14px",
                        }}
                      >
                        {uploadedFileName}
                      </StyledNonBoldHeader>
                    </div>
                  )}
                </ActionSelectOrUploadContainer>
              )}
            </NewActionLabelContainer>
          )}
        </NewActionWrapper>
      </CardContainer>
    </CreateActionContainer>
  );
}

export default CreateActionSection;
