import React, { useState, useEffect } from "react";
import { Modal, Icon, Grid } from "semantic-ui-react";
import { ActionButton } from "../ActionButtons";
import { DownloadMetadataModal } from "./DownloadMetadataModal";
import { DownloadFilteredMetadataModal } from "./DownloadFilteredMetadataModal";
import BulkMetadataUpdateModal from "./BulkMetadataUpdateModal";
import { ThinDivider } from "../../../Dashboards/Panel/util";
import { searchDevicesFilters } from "../../../../../BytebeamClient";

type BulkMetadataOperationsModalProps = {
  readonly isOpen: boolean;
  readonly close: (...args: any[]) => any;
  readonly totalDevices: number;
  readonly setLoading: (...args: any[]) => void;
  readonly getDevicesFilters: () => searchDevicesFilters;
  readonly theme: string;
  readonly selectedDevices: string[];
};

function BulkMetadataOperationsModal(props: BulkMetadataOperationsModalProps) {
  const {
    isOpen,
    close,
    totalDevices,
    setLoading,
    getDevicesFilters,
    theme,
    selectedDevices,
  } = props;
  const [renderBulkDownloadMetadata, setRenderBulkDownloadMetadata] =
    useState(false);
  const [renderFilteredDownloadMetadata, setRenderFilteredDownloadMetadata] =
    useState(false);
  const [renderBulkUpdateMetadata, setRenderBulkUpdateMetadata] =
    useState(false);

  const devicesSearchFilters = getDevicesFilters();

  useEffect(() => {
    return () => {
      setRenderBulkDownloadMetadata(false);
      setRenderFilteredDownloadMetadata(false);
      setRenderBulkUpdateMetadata(false);
    };
  }, [isOpen]);

  return (
    <Modal open={isOpen} onClose={close} size="tiny" className="dark">
      <Modal.Header>Metadata Bulk Operations</Modal.Header>
      {!renderBulkDownloadMetadata &&
        !renderBulkUpdateMetadata &&
        !renderFilteredDownloadMetadata && (
          <Modal.Content>
            <Grid>
              <Grid.Row
                style={{
                  paddingBottom: "0px",
                }}
              >
                <Grid.Column
                  width={9}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      justifyContent: "flex-start",
                      fontSize: "16px",
                      fontWeight: 700,
                    }}
                  >
                    Bulk Download
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      marginBottom: "8px",
                    }}
                  >
                    Click here to download metadata for{" "}
                    {devicesSearchFilters ? "multiple" : "all"} devices
                  </div>
                </Grid.Column>
                <Grid.Column
                  width={7}
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {(devicesSearchFilters?.stateFilters ||
                    devicesSearchFilters.metaDatafilters ||
                    selectedDevices.length > 0) && (
                    <ActionButton
                      onClick={() => setRenderFilteredDownloadMetadata(true)}
                      style={{
                        background: "transparent",
                        marginRight: "0px",
                        marginBottom: "10px",
                        color: theme === "light" ? "#17191d" : "#C1C1C1",
                        border: `1px solid ${
                          theme === "light" ? "#17191d" : "#C1C1C1"
                        }`,
                        padding: "6px 12px",
                        borderRadius: "4px",
                        fontSize: "13px",
                        width: "145px",
                      }}
                    >
                      <Icon name="cloud download" />{" "}
                      <span>Filter Download</span>
                    </ActionButton>
                  )}
                  <ActionButton
                    onClick={() => setRenderBulkDownloadMetadata(true)}
                    style={{
                      background: "transparent",
                      marginRight: "0px",
                      color: theme === "light" ? "#17191d" : "#C1C1C1",
                      border: `1px solid ${
                        theme === "light" ? "#17191d" : "#C1C1C1"
                      }`,
                      padding: "6px 12px",
                      borderRadius: "4px",
                      fontSize: "13px",
                      width: "145px",
                    }}
                  >
                    <Icon name="cloud download" /> <span>Download All</span>
                  </ActionButton>
                </Grid.Column>
              </Grid.Row>

              <ThinDivider />

              <Grid.Row
                style={{
                  padding: "0px",
                }}
              >
                <Grid.Column
                  width={9}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                    }}
                  >
                    Bulk Update
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      marginBottom: "8px",
                    }}
                  >
                    Click here to update metadata for multiple devices in bulk
                  </div>
                </Grid.Column>
                <Grid.Column
                  width={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <ActionButton
                    onClick={() => setRenderBulkUpdateMetadata(true)}
                    style={{
                      background: "transparent",
                      marginRight: "0px",
                      color: theme === "light" ? "#17191d" : "#C1C1C1",
                      border: `1px solid ${
                        theme === "light" ? "#17191d" : "#C1C1C1"
                      }`,
                      padding: "6px 21px",
                      borderRadius: "4px",
                      fontSize: "13px",
                      width: "145px",
                    }}
                  >
                    <Icon name="cloud upload" /> <span>Update</span>
                  </ActionButton>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
        )}
      {renderBulkDownloadMetadata && !renderBulkUpdateMetadata && (
        <DownloadMetadataModal
          totalDevices={totalDevices}
          close={() => setRenderBulkDownloadMetadata(false)}
        />
      )}
      {renderFilteredDownloadMetadata && !renderBulkUpdateMetadata && (
        <DownloadFilteredMetadataModal
          devicesSearchFilters={devicesSearchFilters}
          close={() => setRenderFilteredDownloadMetadata(false)}
        />
      )}
      {!renderBulkDownloadMetadata && renderBulkUpdateMetadata && (
        <BulkMetadataUpdateModal
          close={() => setRenderBulkUpdateMetadata(false)}
          totalDevices={totalDevices}
          setLoading={setLoading}
        />
      )}
    </Modal>
  );
}

export default BulkMetadataOperationsModal;
