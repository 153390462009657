import React from "react";
import { Button, Image, Popup } from "semantic-ui-react";
import { EditPanelWidget } from "./EditPanelWidget";
import { PanelMetaData, PanelDef } from "./PanelDef";
import {
  panelTypeToPanelDefs,
  FetchParams,
  fleetPanelTypes,
  devicePanelTypes,
  devicePanelTooltip,
} from "./util";
import styled from "styled-components";
import { Settings, isHostMicelio } from "../../../../util";
import { capitalizeFirstLetter } from "../../util";
import { DashboardsInfo } from "../ViewDashboard";
import { panelImages } from "./imageUtil";
import { TableInfo, getTenantFromURL } from "../../../../BytebeamClient";
import { DashboardMeta } from "../EditDashboardModal";
import betaLabel from "../../common/images/betaLabel.png";
import numerosLabel from "../../common/images/numerosLabel.png";
import { beamtoast } from "../../../common/CustomToast";

type AddPanelModalProps = {
  onCancel: () => void;
  onSubmit: (
    metaData: PanelMetaData,
    panelDef: PanelDef<PanelMetaData, {}>
  ) => void;
  tables: TableInfo;
  settings: Settings;
  dashboards: DashboardsInfo[];
  fetchParams: FetchParams;
  dashboardMeta: DashboardMeta;
  hideCancelButton: boolean;
};

type AddPanelModalState<MetaDataType, DataType> = {
  panelMeta?: MetaDataType;
  panelDef?: PanelDef<MetaDataType, DataType>;
};

const PanelSelectionMenu = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const PanelHeader = styled.div`
  font-size: large;
  margin: 20px;
`;

const PanelButton = styled.div`
  width: 140px;
  height: 140px;
  margin-bottom: 20px;
  margin-left: 24px;
`;

const ImageCaption = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors["panelButton-text-color"]};

  &:hover {
    color: ${({ theme }) => theme.colors["panelButton-text-hover-color"]};
  }
`;

export class AddPanelModal extends React.Component<
  AddPanelModalProps,
  AddPanelModalState<any, any>
> {
  editPanelWidgetRef = React.createRef<EditPanelWidget<any, any>>();

  constructor(props) {
    super(props);

    this.state = {
      panelMeta: props.panelMeta,
    };
  }

  escFunction(event) {
    event.stopPropagation();
    if (event.key === "Escape") {
      if (this.state.panelDef) {
        this.setState({ panelDef: undefined });
      }
    }
  }

  onSubmit() {
    const editWidget = this.editPanelWidgetRef.current;

    if (editWidget) {
      const result = editWidget.getPanelMeta("submit");
      if (result && this.state.panelDef) {
        if (!result.complete) {
          beamtoast.error(
            result.errorMessage ?? "Please enter value for mandatory fields."
          );
          return;
        }
        this.props.onSubmit(result.meta, this.state.panelDef);
      }
    }
  }

  onBackPress() {
    this.setState({ panelDef: undefined });
  }

  generateEditWidget() {
    if (this.state.panelDef) {
      this.escFunction = this.escFunction.bind(this);
      document.addEventListener("keydown", this.escFunction, false);

      return (
        <EditPanelWidget
          ref={this.editPanelWidgetRef}
          panelDef={this.state.panelDef}
          panelMeta={this.state.panelMeta}
          fetchParams={this.props.fetchParams}
          tables={this.props.tables}
          settings={this.props.settings}
          dashboards={this.props.dashboards}
          dashboardMeta={this.props.dashboardMeta}
        />
      );
    }
  }

  generateSelectionMenu() {
    let allPanelTypes = fleetPanelTypes;
    if (this.props.dashboardMeta?.type === "device") {
      allPanelTypes = devicePanelTypes;
    }

    let tenant = getTenantFromURL();
    let autoTenants = ["evage", "evagedemo", "tafedemo", "ekaevbus", "demo"];

    if (autoTenants.includes(tenant)) {
      allPanelTypes = [...allPanelTypes, "device_status"];
    }

    if (isHostMicelio()) {
      allPanelTypes = [
        ...allPanelTypes,
        "micelio_locate_devices",
        "micelio_track_devices",
        "micelio_status_table",
        "micelio_stats_panel",
        "micelio_status",
        "micelio_alerts_panel",
      ];
    }

    return (
      <PanelSelectionMenu>
        {allPanelTypes.map((panelType) => {
          const SVGImageComponent = panelImages[panelType];

          return (
            <PanelButton key={panelType}>
              {panelType === "state_timeline" && (
                <div
                  style={{
                    position: "absolute",
                  }}
                >
                  <Image src={betaLabel} size="mini" className="beta-label" />
                </div>
              )}
              {panelType.includes("micelio") && (
                <div
                  style={{
                    position: "absolute",
                  }}
                >
                  <Image
                    src={numerosLabel}
                    size="mini"
                    className="beta-label"
                  />
                </div>
              )}
              <Popup
                inverted
                wide
                position="top center"
                content={devicePanelTooltip?.[panelType]}
                header={capitalizeFirstLetter(panelType)}
                trigger={
                  <Button
                    className="add-panel-button"
                    onClick={() => this.showEditModal(panelType)}
                  >
                    {SVGImageComponent && (
                      <SVGImageComponent
                        className="svgImageComponent"
                        width={"100px"}
                        height={"96px"}
                      />
                    )}
                    <ImageCaption className="imageCaption">
                      {capitalizeFirstLetter(panelType).replace(
                        "Micelio",
                        "Numeros"
                      )}
                    </ImageCaption>
                  </Button>
                }
              />
            </PanelButton>
          );
        })}
      </PanelSelectionMenu>
    );
  }

  showEditModal(panelType: string) {
    const panelDef: PanelDef<any, any> = panelTypeToPanelDefs[panelType];
    this.setState({
      panelDef: panelDef,
      panelMeta: new panelDef.metaConstructor(),
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
    return (
      <div className="dark" style={{ width: "100%" }}>
        <PanelHeader>
          {this.props.hideCancelButton
            ? "Get started by adding a new panel"
            : this.state.panelMeta?.type
              ? "Add " + capitalizeFirstLetter(this.state.panelMeta.type)
              : "Add New Panel"}
        </PanelHeader>

        <div>
          {!this.state.panelDef
            ? this.generateSelectionMenu()
            : this.generateEditWidget()}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "top",
          }}
        >
          {!this.props.hideCancelButton && !this.state.panelDef && (
            <Button secondary onClick={this.props.onCancel}>
              Cancel
            </Button>
          )}

          {this.state.panelDef && (
            <Button secondary onClick={this.onBackPress.bind(this)}>
              Back
            </Button>
          )}

          {this.state.panelDef && (
            <Button primary onClick={this.onSubmit.bind(this)}>
              Submit
            </Button>
          )}
        </div>
      </div>
    );
  }
}
