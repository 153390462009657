import React from "react";
import { Popup } from "semantic-ui-react"; // Import Popup from your UI library
import { copyContentToClipboard } from "../../util";
import styled from "styled-components";

const ModifiedToolTip = styled(Popup)<{ showBorder?: boolean }>`
  color: ${({ theme }) => theme.colors["tooltip_primary-text"]} !important;
  border: ${({ theme, showBorder }) =>
    showBorder
      ? theme.colors["live-actions-more-info-popup-border"]
      : "none"} !important;

  &::before {
    border-bottom: ${({ theme, showBorder }) =>
      showBorder
        ? theme.colors["live-actions-more-info-popup-border"]
        : "none"} !important;
    border-right: ${({ theme, showBorder }) =>
      showBorder
        ? theme.colors["live-actions-more-info-popup-border"]
        : "none"} !important;
  }
`;

const SlicedTextSpan = styled.span<{ noWrap?: boolean; notBold?: boolean }>`
  position: relative;
  display: inline-block;
  word-break: break-word;
  white-space: ${({ noWrap }) => (noWrap ? "nowrap" : "wrap")} !important;
  font-weight: ${({ notBold }) => (notBold ? "normal" : "bolder")} !important;
`;

function toSliceText(text: string, length: number): [boolean, string] {
  let sliced = false,
    slicedText = text;

  if (typeof text === "string" || typeof text === "number") {
    slicedText = String(text);
    if (slicedText?.length > length) {
      slicedText = slicedText.substring(0, length);
      sliced = true;
    }
  }
  return [sliced, slicedText];
}

const ListTextLabel = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  line-height: 18px;
  height: 18px !important;
  width: 100% !important;
  color: ${({ theme }) => theme.colors["tooltip_list-text-color"]};
`;

type SliceTextType = {
  readonly text: string;
  readonly length: number;
  readonly noWrap?: boolean;
  readonly notBold?: boolean;
  readonly emptyText?: boolean;
  readonly showBorder?: boolean;
  readonly showAlways?: boolean;
  readonly textList?: string[];
};

/**
 * Renders a component that displays sliced text with optional tooltips and additional text labels.
 *
 * @param text - The text to display.
 * @param textList - An array of strings that contains additional text labels.
 * @param emptyText - A boolean value that determines whether to display an empty text.
 * @param length - The length of the text to display.
 * @param noWrap - A boolean value that determines whether to wrap the text.
 * @param notBold - A boolean value that determines whether to bold the text.
 * @param showAlways - A boolean value that determines whether to show the text always.
 * @param showBorder - A boolean value that determines whether to show the border.
 * @returns The rendered `SlicedTextPopUp` component.
 */
function SlicedTextPopUp(props: SliceTextType) {
  const {
    text,
    length,
    textList,
    noWrap,
    notBold,
    emptyText,
    showBorder,
    showAlways,
  } = props;
  const [sliced, slicedText] = toSliceText(text, length);

  if (!text) {
    return (
      <SlicedTextSpan style={{ cursor: "default" }}>
        {emptyText ? "" : "--"}
      </SlicedTextSpan>
    );
  }

  return (
    <ModifiedToolTip
      hoverable
      showBorder={showBorder}
      disabled={showAlways ? false : !sliced}
      wide
      content={
        <div>
          <SlicedTextSpan noWrap={noWrap} notBold={notBold}>
            {text}
          </SlicedTextSpan>
          {textList !== undefined &&
            textList?.length > 0 &&
            textList?.map((item) => (
              <ListTextLabel key={item}>{item}</ListTextLabel>
            ))}
        </div>
      }
      inverted
      position="top center"
      trigger={
        <SlicedTextSpan
          className="slicedTextSpan"
          noWrap={noWrap}
          notBold={notBold}
          onClick={async () => await copyContentToClipboard(text)}
          style={{ cursor: text === "--" ? "default" : "pointer" }}
        >
          {sliced ? `${slicedText}...` : slicedText}
        </SlicedTextSpan>
      }
    />
  );
}

export default SlicedTextPopUp;
